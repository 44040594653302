<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/exchange.png" width="22">
          <h5
            style="display: inline-flex;"
            class="modal-title p-0 m-0 ml-3 text-white"
          >
            {{ entity ? modal.title : 'Deposito não identificado' }}
          </h5>
        </div>
      </template>
      <div>
        <div
          class="container-fluid pt-2"
        >
          <div
            class="row mb-4 pb-3"
          >
            <div
              class="col-12 text-center"
            >
              <div
                class="tab-switchers"
              >
                <div
                  class="tab"
                  :class="{'active': view === 'sale'}"
                  style="margin-right: .5px;"
                  @click.prevent="view = 'sale'"
                >
                  <div
                    class="tab-content"
                  >
                    <img
                      src="/img/icons/icons8/ios/exchange-dollar_primary.png"
                      width="20"
                    />
                    <h4
                      class="font-weight-400 m-0"
                    >
                      Venda
                    </h4>
                  </div>
                </div>
                <div
                  class="tab"
                  style="margin-left: .5px;"
                  :class="{'active': view === 'installments', 'disabled': payload.installments.length < 1}"
                  @click.prevent="payload.installments.length < 1 ? '' : view = 'installments'"
                >
                  <div
                    class="tab-content"
                  >
                    <img
                      src="/img/icons/crowdfunding-green.png"
                      width="20"
                    />
                    <h4
                      class="font-weight-400 m-0"
                    >
                      Parcelas
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(store)"
              autocomplete="off"
            >
              <div
                class="container-fluid"
                v-show="view === 'sale'"
              >
                <!-- Seção: CLIENTE -->
                <div v-if="entity" class="row mb-3">
                  <div
                    class="col-12"
                    style="display: flex; align-items: center;"
                  >
                    <img
                      src="/img/icons/icons8/ios/contract-job-primary.png"
                      width="20"
                    >
                    <h4
                      class="new-default-black-font m-0 mx-3"
                      style="white-space: nowrap;"
                    >
                      Cliente
                    </h4>
                    <hr class="ml-3 m-0" style="width: 100%;">
                  </div>
                </div>
                <div
                  v-if="entity"
                  class="row mb-3 align-items-center"
                >
                  <div
                    class="col-12"
                  >
                    <h4
                      class="new-default-black-font font-weight-normal m-0"
                    >
                      {{ entity.entity_name }}
                    </h4>
                    <h4
                      class="new-default-black-font font-weight-normal mt-1"
                    >
                      {{ entity.document }}
                    </h4>
                  </div>
                </div>
                <!-- Seção: OPERAÇÃO -->
                <div
                  class="row mb-3"
                >
                  <div
                    class="col-12"
                    style="display: flex; align-items: center;"
                  >
                    <img
                      src="/img/icons/icons8/ios/exchange-dollar_primary.png"
                      width="20"
                    >
                    <h4
                      class="new-default-black-font m-0 mx-3"
                      style="white-space: nowrap;"
                    >
                      Operação
                    </h4>
                    <hr class="ml-3 m-0" style="width: 100%;">
                  </div>
                </div>
                <!-- DATA DA VENDA -->
                <div
                  class="row mt-2 align-items-center"
                >
                  <div
                    class="col-6"
                  >
                    <h4
                      class="new-default-black-font font-weight-normal m-0"
                    >
                      Data da Venda
                    </h4>
                  </div>
                  <div
                    class="col-6"
                  >
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <InputDateTimePicker
                          @input="generateInstallments(1)"
                          v-model="payload.bill_receive.competence"
                          :mode="'date'"
                          :disableMaxDate="new Date()" />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <!-- VALOR BRUTO -->
                <div class="row mt-2 align-items-center">
                  <div class="col-6 mt-1">
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Valor Bruto
                    </h4>
                  </div>
                  <div class="col-6">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        @blur="generateInstallments(1)"
                        v-money="money"
                        maxlength="13"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                        type="text"
                        v-model.lazy="payload.bill_receive.total_value"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
                <!-- Método de pagamento -->
                <div
                  class="row mt-2 align-items-center"
                >
                  <div
                    class="col-6"
                  >
                    <h4
                      class="new-default-black-font font-weight-normal m-0"
                    >
                      Método de pagamento
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </h4>
                  </div>
                  <div
                    class="col-6"
                  >
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          @change="getPaymentTerms(true)"
                          @input="setRequireNsuCode()"
                          v-model="payload.bill_receive.payment_method_id"
                          filterable
                          :loading="loadingPlant"
                          :disabled="loadingPlant || payload.bill_receive.total_value === '0,00'"
                          :items="listPaymentMethodsWithActiveAnticipation"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <!-- Condição de pagamento -->
                <div
                  class="row mt-2 align-items-center"
                >
                  <div
                    class="col-6"
                  >
                    <h4
                      class="new-default-black-font font-weight-normal m-0"
                    >
                      Condição de pagamento
                      <span
                        class="text-danger"
                        v-show="hasInstallmentPaid"
                      >
                        &nbsp;*&nbsp;
                      </span>
                    </h4>
                  </div>
                  <div
                    class="col-6"
                  >
                    <validation-provider :rules="(hasInstallmentPaid && 'required') || ''">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          @change="generateInstallments"
                          v-model="payload.bill_receive.payment_term_id"
                          filterable
                          :disabled="loadingPlant || payload.bill_receive.total_value === '0,00' || !payload.bill_receive.payment_method_id"
                          :items="payment_terms_selected"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <!-- Mediador de pagamento -->
                <div
                  v-show="payment_intermediaries_exists"
                  class="row mt-2 align-items-center"
                >
                  <div
                    class="col-6"
                  >
                    <h4
                      class="new-default-black-font font-weight-normal m-0"
                    >
                      Mediador de pagamento
                      <span
                        class="text-danger"
                        v-show="payment_intermediaries_exists"
                      >
                        &nbsp;*&nbsp;
                      </span>
                    </h4>
                  </div>
                  <div
                    class="col-6"
                  >
                    <validation-provider :rules="((hasInstallmentPaid || payment_intermediaries_exists) && 'required') || ''">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          placeholder="Nenhum"
                          @input="generateInstallments();setBankAccount()"
                          @change="getPaymentIntermediaryMerchants()"
                          :items="payment_intemerdiaries_selected"
                          :disabled="loadingPaymentIntermediaries || !payload.bill_receive.payment_method_id"
                          v-model="payload.bill_receive.payment_intermediary_id"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div v-show="payment_intermediaries_exists" class="row mt-2">
                  <h4 class="new-default-black-font font-weight-normal m-0 col-md-6">
                    Bandeira
                  </h4>
                  <div class="col-md-6">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        placeholder="Nenhum"
                        :items="$_payment_brands"
                        :disabled="loadingPaymentIntermediaries || loadingPaymentBrands"
                        v-model="payload.bill_receive.payment_brand_id"
                      />
                    </base-input>
                  </div>
                </div>
                <!-- NSU da transação -->
                <div
                  v-show="payment_intermediaries_exists"
                  class="row mt-2 align-items-center"
                >
                  <div
                    class="col-6"
                  >
                    <h4
                      class="new-default-black-font font-weight-normal m-0"
                    >
                      NSU da transação
                      <span
                        class="text-danger"
                        v-show="require_nsu_code"
                      >
                        &nbsp;*&nbsp;
                      </span>
                      <span v-if="
                        modalCardIntegrations &&
                        payload.bill_receive.payment_intermediary_id &&
                        payload.bill_receive.payment_intermediary_merchant_id &&
                        (payload.bill_receive.payment_method_id === 2 ||
                        payload.bill_receive.payment_method_id === 3)"
                      >
                        <!-- <a href="javascript:void(0)"
                          @click.prevent="handleCreateModalCardIntegration"
                          class="fa-regular fa-credit-card text-success fa-xl">
                        </a> -->
                      </span>
                    </h4>
                  </div>
                  <div
                    class="col-6"
                  >
                    <validation-provider :rules="(require_nsu_code && 'required') || ''">
                      <base-input input-classes="form-control-sm">
                        <input
                          inputmode="numeric"
                          v-mask="'####################'"
                          v-model="payload.bill_receive.nsu_code"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <!-- Seção: IDENTIFICAÇÃO -->
                <div
                  class="row mt-3 mb-3"
                >
                  <div
                    class="col-12"
                    style="display: flex; align-items: center;"
                  >
                    <img
                      src="/img/icons/icons8/ios/info-squared_warning.png"
                      width="20"
                    >
                    <h4
                      class="new-default-black-font m-0 mx-3"
                      style="white-space: nowrap;"
                    >
                      Beneficiário
                    </h4>
                    <hr class="ml-3 m-0" style="width: 100%;">
                  </div>
                </div>
                <!-- CENTRAL -->
                <div
                  class="row mt-2 align-items-center"
                >
                  <div
                    class="col-6"
                  >
                    <h4
                      class="new-default-black-font font-weight-normal m-0"
                    >
                      Central
                    </h4>
                  </div>
                  <div class="col-6">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect
                        @input="changeBankAccount()"
                        @change="setIssuer()"
                        :disabled="payload.invoices.length > 0"
                        v-model="payload.bill_receive.company_plant_id"
                        :items="$_company_plants"
                        :loading="loadingCompanyPlants"
                        filterable
                      />
                    </base-input>
                  </div>
                </div>
                <!-- CNPJ BENEFICIÁRIO -->
                <div
                  class="row mt-2 align-items-center"
                >
                  <div
                    class="col-6 mt-1"
                  >
                    <h4
                      class="new-default-black-font font-weight-normal m-0"
                    >
                      Filial
                      <span class="text-danger">&nbsp;*</span>
                    </h4>
                  </div>
                  <div
                    class="col-6"
                  >
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          :disabled="payload.invoices.length > 0 || payload.bill_receive.company_plant_id === null"
                          v-model="payload.bill_receive.company_plant_issuer_id"
                          :items="company_plant_issuers"
                          :loading="loadingCompanyPlantIssuers"
                          label="alias_name"
                          filterable
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <!-- CONTA BANCÁRIA -->
                <div
                  class="row mt-2 align-items-center"
                >
                  <div
                    class="col-6"
                  >
                    <h4
                      class="new-default-black-font font-weight-normal m-0"
                    >
                      Conta Bancária
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </h4>
                  </div>
                  <div
                    class="col-6"
                  >
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          v-model="payload.bill_receive.bank_account_id"
                          filterable
                          :disabled="hasBankAccountFixed"
                          :items="bank_accounts"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <!-- Observações -->
                <div
                  class="row mt-2 mb-3"
                >
                  <div class="col-6">
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Observações
                    </h4>
                  </div>
                  <div class="col-6">
                    <base-input input-group-classes="input-group-sm">
                      <textarea type="text" rows="3"
                        v-model="payload.bill_receive.observations"
                        class="form-control form-control-sm" placeholder=" ...">
                      </textarea>
                    </base-input>
                  </div>
                </div>
                <div
                  class="row mt-2"
                >
                  <div class="col-12">
                    <label class="form-control-label">Foto do Documento</label>
                    <InputFile :maxFiles="10" ref="files"/>
                  </div>
                </div>
                <!-- Ações -->
                <div class="row mt-4 mb-4">
                  <div class="col-12 text-right">
                    <base-button
                      type="danger"
                      @click="closeModal('create')"
                      size="sm"
                      style="width: 150px;"
                    >
                      <div style="display: inline-flex; align-items: center;">
                        <img src="/img/icons/cancel-white.png" width="20">
                        <i class="m-0 ml-1" style="font-style: normal;">
                          Cancelar
                        </i>
                      </div>
                    </base-button>
                    <base-button type="success"
                      size="sm"
                      style="width: 150px;"
                      native-type="submit"
                      :disabled="invalid || !payload.installments.length"
                      :loading="loadingStore"
                    >
                      <div style="display: inline-flex; align-items: center;">
                        <img src="/img/icons/icons8/ios/save.png" width="20">
                        <i class="m-0 ml-1" style="font-style: normal;">
                          Salvar
                        </i>
                      </div>
                    </base-button>
                  </div>
                </div>
              </div>
              <div
                class="container-fluid"
                v-show="view === 'installments'"
              >
                <div class="row mb-3">
                  <div
                    class="col-12"
                    style="display: flex; align-items: center;"
                  >
                    <img
                      src="/img/icons/crowdfunding-green.png"
                      width="20"
                    >
                    <h4
                      class="new-default-black-font m-0 mx-3"
                      style="white-space: nowrap;"
                    >
                      Parcelas
                    </h4>
                    <hr class="ml-3 m-0" style="width: 100%;">
                  </div>
                </div>
                <div
                  class="row mt-2 align-items-center"
                >
                  <div class="col-12">
                    <div
                      class="installments-viewer"
                      :style="payload.installments.length > 1 && 'overflow: auto;'"
                    >
                      <div
                        class="container-fluid mt-4 mb-4"
                        v-for="(installment, indexInstallment) in payload.installments"
                        :key="installment.index"
                      >
                        <div class="row px-3 align-items-center">
                          <div class="col-12 mb-3 mb-lg-0 col-lg-6 px-0 ml-lg-n2 mr-2">
                            <div
                              style="display: flex; align-items: center;"
                              class="mt-3"
                            >
                              <div class="dot-ellipse">
                                <div></div>
                              </div>
                              <h3 class="mx-2 new-default-black-font m-0">
                                Parcela {{ installment.index }}
                              </h3>
                              <!-- <div
                                class="new-minicard-table"
                                style="cursor: auto;"
                                :class="getColor(installment.status)"
                                slot="title-container">
                                <div>
                                  <img :src="statusIcon[`${installment.status}`]">
                                  <h4>
                                    {{ getTitle(installment.status) }}
                                  </h4>
                                </div>
                              </div> -->
                            </div>
                          </div>
                          <!-- VENCIMENTO -->
                          <div class="col-6 col-lg-3 px-2">
                            <label class="new-default-black-font font-weight-500">
                              Vencimento
                              <span class="text-danger">&nbsp;*</span>
                            </label>
                            <validation-provider rules="required">
                              <base-input input-group-classes="custom-input-group280920241830">
                                <input-date-time-picker
                                  v-model="installment.due_date"
                                  :mode="'date'"
                                />
                              </base-input>
                            </validation-provider>
                          </div>
                          <!-- VALOR BRUTO -->
                          <div class="col-6 col-lg-3 px-2">
                            <label class="new-default-black-font font-weight-500">
                              Valor Bruto
                              <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="input-custom-group">
                              <div>R$</div>
                              <input
                                @blur="calcTotalValue(installment, indexInstallment)"
                                v-money="money"
                                @keydown="
                                  $event.key === '-'
                                    ? $event.preventDefault()
                                    : null
                                "
                                maxlength="13"
                                inputmode="numeric"
                                v-model.lazy="installment.value"
                                type="text"
                                class="form-control form-control-sm"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="row px-3 mt-3 mt-lg-4 align-items-center">
                          <!-- DESCONTOS -->
                          <div class="col-6 col-lg-3 px-2">
                            <label class="new-default-black-font font-weight-500">Descontos</label>
                            <div class="input-custom-group">
                              <div>R$</div>
                              <input
                                @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                @blur="calcTotalValue(installment, indexInstallment)"
                                v-money="money"
                                maxlength="13"
                                inputmode="numeric"
                                v-model="installment.discounts"
                                class="form-control form-control-sm"
                              >
                            </div>
                          </div>
                          <!-- TAXAS -->
                          <div class="col-6 col-lg-3 px-2">
                            <label class="new-default-black-font font-weight-500">Taxas</label>
                            <div class="input-custom-group">
                              <div>R$</div>
                              <input
                                @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                @blur="calcTotalValue(installment, indexInstallment)"
                                v-money="money"
                                maxlength="13"
                                inputmode="numeric"
                                v-model="installment.discounts_fees"
                                class="form-control form-control-sm"
                              >
                            </div>
                          </div>
                          <!-- JUROS/MULTA -->
                          <div class="col-6 mt-3 mt-lg-0 col-lg-3 px-2">
                            <label class="new-default-black-font font-weight-500">Juros/Multas</label>
                            <div class="input-custom-group">
                              <div>R$</div>
                              <input
                                @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                ref="feesInput"
                                @blur="calcTotalValue(installment, indexInstallment)"
                                v-money="money"
                                maxlength="13"
                                inputmode="numeric"
                                v-model="installment.fees_fines"
                                class="form-control form-control-sm"
                              >
                            </div>
                          </div>
                          <!-- VALOR FINAL -->
                          <div class="col-6 mt-3 mt-lg-0 col-lg-3 px-2">
                            <label class="new-default-black-font font-weight-500">
                              Valor Final
                              <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="input-custom-group primary">
                              <div>R$</div>
                              <input
                                @keydown="$event.key === '-' ? $event.preventDefault() : null"
                                disabled
                                v-money="money"
                                maxlength="13"
                                inputmode="numeric"
                                v-model.lazy="installment.total_value"
                                type="text"
                                class="form-control form-control-sm"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="row mt-4 pt-2" v-if="payload.installments.length != 1 && installment.index != payload.installments.length">
                          <div class="col-12">
                            <hr class="blue-divider m-0">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Ações -->
                <div class="row mt-5 mb-4">
                  <div class="col-12 text-right">
                    <base-button type="danger"
                      @click="closeModal('create')"
                      size="sm"
                      style="width: 150px;"
                    >
                      <div style="display: inline-flex; align-items: center;">
                        <img src="/img/icons/cancel-white.png" width="20">
                        <i class="m-0 ml-1" style="font-style: normal;">
                          Cancelar
                        </i>
                      </div>
                    </base-button>
                    <base-button type="success"
                      size="sm"
                      style="width: 150px;"
                      native-type="submit"
                      :disabled="invalid || !payload.installments.length"
                      :loading="loadingStore"
                    >
                      <div style="display: inline-flex; align-items: center;">
                        <img src="/img/icons/icons8/ios/save.png" width="20">
                        <i class="m-0 ml-1" style="font-style: normal;">
                          Salvar
                        </i>
                      </div>
                    </base-button>
                  </div>
                </div>
              </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <ModalCardIntegration ref="ModalCardIntegration" @selectedTransactionProps="setTransactionProps"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import PuzlFile from "@/components/PuzlFile";
import {VMoney} from "v-money";
import {checkNumberValue} from "@/helpers";
import {formatErrorValidation, mask1} from "@/plugins";
import moment from "moment";
import InputFile from "@/components/Utils/InputFile";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import ModalCardIntegration from "../../../Shared/Modals/_ModalCardIntegration";

export default {
  name: "ModalCreateEntity",
  components: {
    InputFile,
    PuzlFile,
    SpinnerPuzl,
    PuzlSelect,
    InputDateTimePicker,
    ModalCardIntegration,
  },
  data() {
    return {
      loadingBankAccounts: false,
      loadingCompanyPlantIssuers: false,
      loadingPaymentIntermediaries: false,
      loadingPaymentIntermediaryMerchants: false,
      loadingCompanyPlants: false,
      entity: null,
      modal: {
        title: "Operação Financeira",
        create: false,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      view: 'sale',
      bank_accounts: [],
      require_nsu_code: false,
      type: 1,
      loadingSpinner: false,
      payment_terms_selected: [],
      payload: {
        bill_receive: {
          type: null,
          company_plant_issuer_id: null,
          company_plant_id: null,
          competence: null,
          name: null,
          total_value: null,
          observations: null,
          final_value: null,
          payment_term_id: null,
          payment_method_id: null,
          bank_account_id: null,
          payment_intermediary_id: null,
          payment_intermediary_merchant_id: null,
          transaction_code: null,
          nsu_code: null,
          iss_retain: null,
          payment_brand_id: null,
        },
        installments: [],
        invoices: [],
      },
      payment_intermediaries_exists: false,
      payment_intemerdiaries_selected: [],
      payment_intermediary_merchants: [],
      hasInstallmentPaid: false,
      contract_proposal: {},
      loadingStore: false,
      loadingPlant: false,
      loadingMethod: true,
      loadingPaymentBrands: false,
      loading: true,
      hasBankAccountFixed: false,
      file: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      invoiced_amount: null,
      balance: null,
      modalCardIntegrations: false,
      statusIcon: {
        '0': "/img/icons/icons8/ios/hourglass_warning.png",
        '1': "/img/icons/icons8/ios/receive-dollar_success.png",
        '2': "/img/icons/icons8/ios/cancel_danger.png",
        '3': "/img/icons/icons8/ios/cancel_danger.png",
      },
    };
  },
  directives: {
    money: VMoney,
  },
  watch: {},
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      payment_methods: "paymentMethod/fetch",
      payment_terms: "paymentTerm/fetch",
      entities: "entity/fetch",
      $_company_plants: "plant/activeItems",
      company_plant_issuers: "companyPlantIssuer/fetch",
      $_payment_intermediaries: "paymentIntermediary/fetch",
      $_payment_brands : "paymentBrand/fetch",
    }),
    listPaymentMethodsWithActiveAnticipation() {
      return this.payment_methods.filter(item => item.display_in_anticipation);
    },
  },
  methods: {
    setBankAccount() {
      if (this.payload.bill_receive.payment_intermediary_id) {
        this.payload.bill_receive.bank_account_id = null;
        let payment_intermediary = this.$_payment_intermediaries.find((item) => item.id === this.payload.bill_receive.payment_intermediary_id)
        if (payment_intermediary.bank_account_id_is_fixed) {
          this.loadingBankAccounts = true;
          this.$store
            .dispatch("bankAccount/fetchItems", {filter:{status:true}})
            .then((response) => {
              this.loadingBankAccounts = false;
              this.hasBankAccountFixed = true
              this.bank_accounts = response.data;
              this.payload.bill_receive.bank_account_id = payment_intermediary.bank_account_id_fixed
            })
        } else {
          this.hasBankAccountFixed = false
          this.getBankAccounts()
        }
      }
    },
    /**
     * Exibir campos de ponto de venda de acordo com ID do Mediador de Pagamento
     *
     * @param {integer} paymentIntermediaryId Id de mediador de pagamento
     * @returns {boolean} Exibir/Não exibir pontos de venda
     */
    isDisplayPaymentIntermediaryMerchants(paymentIntermediaryId) {
      const paymentIntermediaryIdFound = this.payment_intemerdiaries_selected.find((item) => item.id === paymentIntermediaryId);
      return paymentIntermediaryIdFound
        && paymentIntermediaryIdFound.has_credentials
        && (this.payment_intermediary_merchants.length > 0);
    },
    handleCreateModalCardIntegration() {
      this.$refs.ModalCardIntegration.handleCreateModal(
        this.payload.bill_receive.payment_intermediary_id,
        this.payload.bill_receive.payment_intermediary_merchant_id,
        this.payload.bill_receive.competence,
      )
    },
    /**
     * @param {object} selectedTransactionProps
     * @param {integer} selectedTransactionProps.payment_intermediary_id
     * @param {integer} selectedTransactionProps.paymentIntermediaryMerchantId
     * @param {string} selectedTransactionProps.nsu_code
     */
    setTransactionProps(selectedTransactionProps) {
      this.payload.bill_receive.payment_intermediary_id = selectedTransactionProps.paymentIntermediaryId;
      this.payload.bill_receive.payment_intermediary_merchant_id = selectedTransactionProps.paymentIntermediaryMerchantId;
      this.payload.bill_receive.nsu_code = selectedTransactionProps.nsu_code;
      this.payload.bill_receive.competence = selectedTransactionProps.competence;
    },
    getPaymentIntermediaryMerchants() {
      this.payload.bill_receive.payment_intermediary_merchant_id = null
      this.loadingPaymentIntermediaryMerchants = true
      const params = {
        filter: {
          payment_intermediary_id: this.payload.bill_receive.payment_intermediary_id,
          status: true,
        }
      };
      this.$store
        .dispatch("paymentIntermediaryMerchant/fetchItems", params)
        .then(response => {
          this.payment_intermediary_merchants = response.data;
          if (this.payment_intermediary_merchants.length === 1) {
            this.payload.bill_receive.payment_intermediary_merchant_id = this.payment_intermediary_merchants[0].id;
          }
          this.loadingPaymentIntermediaryMerchants = false;
        });
    },
    setIssuer() {
      this.payload.bill_receive.company_plant_issuer_id = null;
      this.loadingCompanyPlantIssuers = true;
      this.$store
        .dispatch("companyPlantIssuer/fetchItems", {
          allocated: true,
          company_plant_id: this.payload.bill_receive.company_plant_id,
        })
        .then((response) => {
          const plant = this.plants.find(
            (item) => item.id === this.payload.bill_receive.company_plant_id
          );
          const default_plant_issuer = plant.company_issuers.find(
            (item) => item.default === 1
          );
          if (default_plant_issuer) {
            this.payload.bill_receive.company_plant_issuer_id =
              default_plant_issuer.id;
            this.setInvoice();
          }
          this.loadingCompanyPlantIssuers = false;
        })
        .catch(() => {
          this.loadingCompanyPlantIssuers = false;
        });
    },
    closeModal() {
      this.modal.create = false;
    },
    getBankAccounts() {
      if (this.payload.bill_receive.payment_intermediary_id) {
        let payment_intermediary = this.$_payment_intermediaries.find((item) => item.id === this.payload.bill_receive.payment_intermediary_id)

        if (payment_intermediary.bank_account_id_is_fixed) {
          return
        }
      }
      if (!this.payload.bill_receive.company_plant_id) {
        return
      }
      this.payload.bill_receive.bank_account_id = null;
      this.loadingBankAccounts = true;

      this.$store.dispatch(
        "bankAccount/fetchItems", {
          filter: {
            in_company_plant_has_bank_accounts: true,
            company_plant_id: this.payload.bill_receive.company_plant_id,
            status: true,
          }
        }).then((response) => {
        this.loadingBankAccounts = false;
        this.bank_accounts = response.data;
        const company_plant = this.$_company_plants.find(
          (item) => item.id === this.payload.bill_receive.company_plant_id
        );
        this.payload.bill_receive.bank_account_id =
          company_plant.bank_account_id;
      });
    },
    changeBankAccount() {
      this.getBankAccounts();
    },
    /** validações por método de pagamento */
    async setRequireNsuCode() {
      this.modalCardIntegrations = true;
      this.payload.bill_receive.payment_intermediary_id = null;
      this.payment_intemerdiaries_selected = [];
      let payment_method = this.payment_methods.find(
        (item) => item.id === this.payload.bill_receive.payment_method_id
      );
      if (!payment_method) {
        this.payment_intermediaries_exists = false;
        return (this.require_nsu_code = false);
      }
      this.payment_intermediaries_exists =
        payment_method.payment_intermediaries_exists;
      if (payment_method.payment_intermediaries_exists) {
        this.payload.installments = [];
        if (payment_method.payment_intermediaries.length === 1) {
          this.payload.bill_receive.payment_intermediary_id =
            payment_method.payment_intermediaries[0];
        }
        this.payment_intemerdiaries_selected =
          this.$_payment_intermediaries.filter(function (item) {
            if (payment_method.payment_intermediaries.includes(item.id) && item.status) {
              return item;
            }
          });
      }
      this.require_nsu_code = Boolean(payment_method.transaction_code);
      if (this.require_nsu_code) {
        this.getPaymentIntermediaryMerchants();
      }
      // Se mediador de pagamento estiver habilitado, busca bandeiras
      if(this.payment_intermediaries_exists) {
        this.loadingPaymentBrands = true;
        await this.$store.dispatch("paymentBrand/fetchActive");
        this.loadingPaymentBrands = false;
      }
    },
    calcTotalValueToReceive() {
      const sum = this.payload.installments.reduce(function (new_value, obj) {
        new_value += parseFloat(obj.value.includes(",") ? obj.value.replaceAll(".", "").replace(",", ".") : obj.value);
        return new_value;
      }, 0);
      return this.payload.bill_receive.total_value = Number(sum).toFixed(2)
    },
    calcFinalValue() {
      let total = 0;
      let total_value = 0;
      let final_value = 0
      for (let i in this.payload.installments) {
        const day = Number(moment(this.payload.installments[i].due_date).day());
        if ([0, 6].includes(day)) {
          if (day === 0) {
            const due_date = moment(this.payload.installments[i].due_date)
              .add(1, "days")
              .format("YYYY/MM/DD");
            this.payload.installments[i].due_date = due_date;
            this.payload.installments[i].original_due_date = due_date;
          }
          if (day === 6) {
            const due_date = moment(this.payload.installments[i].due_date)
              .add(2, "days")
              .format("YYYY/MM/DD");
            this.payload.installments[i].due_date = due_date;
            this.payload.installments[i].original_due_date = due_date;
          }
        }
        total += Number(this.$helper.strToNum(this.payload.installments[i].value))
        final_value += Number(this.$helper.strToNum(this.payload.installments[i].total_value))
      }
      total_value = this.payload.bill_receive.total_value
        .replace(".", "")
        .replace(",", ".");
      if (this.payload.installments.length) {
        if (Number(total_value).toFixed(2) > Number(total).toFixed(2)) {
          const diff = (Number(total_value) - total).toFixed(2);
          this.payload.installments[0].value = Number(
            Number(this.$helper.strToNum(this.payload.installments[0].value)) + Number(diff)
          ).toFixed(2);
        }
        if (Number(total_value).toFixed(2) < Number(total).toFixed(2)) {
          const diff = Number(total).toFixed(2) - Number(total_value).toFixed(2)
          this.payload.installments[0].value = Number(
            Number(this.$helper.strToNum(this.payload.installments[0].value)) - Number(diff)
          ).toFixed(2);
        }
      }
      this.payload.bill_receive.final_value = Number(final_value).toFixed(2);
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "new-minicard-yellow";
        case 1:
          return "new-minicard-green";
        case 2:
        case 3:
          return "new-minicard-red";
      }
    },
    getTitle(status) {
      switch (status) {
        case 0:
          return "Pendente";
        case 1:
          return "Pago";
        case 2:
          return "Atrasado";
        case 3:
          return "Cancelado";
      }
    },
    formatAccountLabel(item) {
      return item.name;
    },
    handleCreateModal(entity, company_plant_id = null, default_value = null) {
      this.view = 'sale';
      this.entity = entity
      this.payment_intermediaries_exists = false;
      this.loadingCompanyPlants = true;
      this.loadingPaymentIntermediaries = true;
      this.modalCardIntegrations = false;
      Promise.all([
        this.$store.dispatch("paymentTerm/fetchItems"),
        this.$store.dispatch("paymentIntermediary/fetchItems"),
        this.$store.dispatch("plant/fetchItemsActive"),
        this.$store.dispatch("paymentMethod/fetchItems"),
      ]).then(() => {
        this.loadingBankAccounts = false;
        this.loadingPaymentIntermediaries = false;
        this.loadingCompanyPlants = false;
        this.modal.create = true;
      });
      this.type = 1;
      this.contract_proposal = {};
      this.payload = {
        bill_receive: {
          competence: null,
          name: null,
          type: null,
          total_value: null,
          observations: null,
          final_value: null,
          payment_term_id: null,
          company_plant_id: company_plant_id,
          company_plant_issuer_id: null,
          payment_method_id: null,
          payment_intermediary_id: null,
          transaction_code: null,
          nsu_code: null,
        },
        installments: [],
        invoices: [],
      };
      if (company_plant_id) {
        this.changeBankAccount()
        this.setIssuer()
      }
      if (default_value) {
        this.payload.bill_receive.total_value = default_value
      }
      this.balance = 0;
      this.invoiced_amount = 0;
    },
    finalFormat() {
      let payload = JSON.parse(JSON.stringify(this.payload));
      payload.bill_receive.total_value = payload.bill_receive.total_value
        .replaceAll(".", "")
        .replace(",", ".");
      payload.bill_receive.type = 0
      payload.bill_receive.entity_id = this.entity ? this.entity.id : null
      payload.bill_receive.final_value = payload.bill_receive.final_value;
      payload.installments.map(function (obj, key) {
        obj.value = obj.value.replaceAll(".", "").replace(",", ".");
        obj.total_value = obj.total_value.replaceAll(".", "").replace(",", ".");
        obj.fees_fines = obj.fees_fines.replaceAll(".", "").replace(",", ".");
        obj.discounts_fees = obj.discounts_fees.replaceAll(".", "").replace(",", ".");
        obj.discounts = obj.discounts.replaceAll(".", "").replace(",", ".");
      });
      return payload;
    },
    validInstallmentPaid() {
      let pending_before_paid = false;
      let has_pending = false;
      for (let item of this.payload.installments) {
        if (item.status != 1) {
          has_pending = true;
          continue;
        }
        if (item.status === 1 && has_pending) {
          pending_before_paid = true;
          break;
        }
      }
      return pending_before_paid;
    },
    async isValidPaymentIntermediaryMerchant() {
      // Campos obrigatórios para consulta
      const paymentIntermediaryMerchantIsFilled = this.payload.bill_receive.payment_intermediary_id
        && this.payload.bill_receive.payment_intermediary_merchant_id
        && this.payload.bill_receive.nsu_code;
      if (!paymentIntermediaryMerchantIsFilled) {
        this.$notify({
          type: "danger",
          message: `Quando NSU da transação é informado, é necessário preencher "Mediador de Pagamento" e "Ponto de Venda (PV)".`
        });
        return false;
      }

      // Verificar existência da transação
      // const params = {
      //   id: this.payload.bill_receive.payment_intermediary_merchant_id,
      //   saleDate: this.$helper.parseDate(this.payload.bill_receive.competence, 'YYYY-MM-DD'),
      //   nsuCode: this.payload.bill_receive.nsu_code,
      // };
      // return await this.$store
      //   .dispatch("paymentIntermediaryMerchant/getSaleFromCardIntegrationByNsuCode", params)
      //   .then((response) => {
      //     if (response.data.length === 0){
      //       this.$notify({
      //         type: "danger",
      //         message: `NSU da transação "${params.nsuCode}" não foi encontrado em competência ${this.$helper.parseDate(params.saleDate, 'DD/MM/YYYY')}`,
      //       });
      //       return false
      //     }
      //     let isValid = true;
      //     const {credentialTypeName, amount, installmentQuantity, typeName, tid} = response.data;
      //     const isCreditTypeName = this.payload.bill_receive.payment_method_id == 2;
      //     this.payload.bill_receive.transaction_code = tid;

      //     // Comparar valor com API de Mediador de Pagamento
      //     if (this.$helper.strToNum(this.payload.bill_receive.total_value) !== amount){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Valor Total (A Receber) cadastrado é diferente do encontrado em ${credentialTypeName}: R$ ${amount.toFixed(2)}.`
      //       });
      //     };

      //     // Comparar quantidade de parcelas com API de Mediador de Pagamento
      //     if (this.payload.installments.length !== installmentQuantity){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Quantidade de Parcelas cadastrada é diferente do encontrado em ${credentialTypeName}: ${installmentQuantity} Parcelas.`
      //       });
      //     }

      //     // Compararar tipo de cartão (Crédito ou Débito)
      //     if ((isCreditTypeName) && (typeName.toUpperCase() !== 'CREDIT')){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Tipo de Cartão [Crédito/Débito] não confere com o encontrado em ${credentialTypeName}: ${typeName.toUpperCase()}O.`
      //       });
      //     }
      //     return isValid;
      //   }).catch(error => {
      //     const technicalMessage = error.status ? error.data.message : formatErrorValidation(error.response.data.errors);
      //     this.$notify({type: "danger", message: `Transação: ${params.nsuCode} não encontrada.`});
      //     this.$notify({type: "danger", message: `Mensagem técnica: ${technicalMessage}.`});
      //     return false;
      //   });
      return true;
    },
    async store() {
      const has_final_less = this.payload.installments.some((item) => Number(item.total_value.replaceAll(".", "").replace(",", ".")) < 0)
      const has_total_less = this.payload.installments.some((item) => Number(item.value.replaceAll(".", "").replace(",", ".")) <= 0)
      if (has_total_less) {
        this.$notify({
          type: "danger",
          message: 'O valor total da parcela não pode ser igual ou menor a R$ 0,00.'
        });
      }
      if (has_final_less) {
        this.$notify({
          type: "danger",
          message: 'O valor total da parcela não pode ser menor a R$ 0,00.'
        });
      }
      if (has_final_less || has_total_less) {
        return;
      }
      // Verificar NSU da transação quando obrigatório
      this.loadingStore = true;
      const payment_intermediary = this.payment_intemerdiaries_selected.find((item) => item.id === this.payload.bill_receive.payment_intermediary_id);
      const hasCredentials = payment_intermediary && payment_intermediary.has_credentials;
      const nsuCodeIsRequired = [2, 3].includes(this.payload.bill_receive.payment_method_id) && hasCredentials;
      if (nsuCodeIsRequired) {
        if (!this.payload.bill_receive.nsu_code) {
          this.$notify({type: "danger", message: `NSU da transação é obrigatório.`});
          this.loadingStore = false;
          return;
        }
        // Desabilitado temporariamente
        // if (!await this.isValidPaymentIntermediaryMerchant()) {
        //   this.loadingStore = false;
        //   return;
        // }
      }
      let formData = new FormData();
      this.payload.bill_receive.competence = new moment(
        this.payload.bill_receive.competence
      ).format("YYYY-MM-DD");
      // this.payload.installments.map(function (item, key) {
      //   for (let indexFile in this.$refs[`input-file-${key}`][0].fileRecords) {
      //     const index_name = key + "-" + indexFile;
      //     formData.append(
      //       index_name,
      //       this.$refs[`input-file-${key}`][0].fileRecords[indexFile]["file"]
      //     );
      //   }
      // }, this);
      this.$Progress.start();
      const payload = this.finalFormat();
      this.loadingStore = true;
      for (let indexFile in this.$refs.files.fileRecords) {
        formData.append(
          indexFile,
          this.$refs.files.fileRecords[indexFile]["file"]
        );
      }
      for (let index in payload) {
        formData.append(index, JSON.stringify(payload[index]));
      }
      this.$store
        .dispatch("billReceive/add", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingStore = false;
          this.closeModal();
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$emit("added", response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    getPaymentMethods() {
      this.$store.dispatch("paymentMethod/fetchItems").then(() => {
        this.loadingMethod = false;
      });
    },
    generateInstallments(recalc = 0) {
      if (recalc === 1) {
        this.payload.bill_receive.final_value =
          this.payload.bill_receive.total_value;
      }
      if (
        this.payload.bill_receive.payment_term_id &&
        this.payload.bill_receive.payment_method_id
      ) {
        let self = this;
        let id = this.payload.bill_receive.payment_term_id;
        let payment_term = this.payment_terms.filter(
          (item) => item.id === id
        )[0];

        let payment_method = this.payment_methods.find(
          (item) => item.id === self.payload.bill_receive.payment_method_id
        );
        /*
         * Buscamos as parcelas da condição de pagamento
         */
        let installments = payment_term.installments;
        let payload_installments = [];
        /*
         * Percorremos as parcelas de condição de pagamento
         * e montamos as nossas para pagamento de proposta contrato.
         */
        // valor total
        const total = Number(
          self.payload.bill_receive.total_value
            .replaceAll(".", "")
            .replace(",", ".")
        );
        // valor retido
        const iss_retain = 0;
        const total_value = total - iss_retain;
        const payment_intermediary = this.$_payment_intermediaries.find(
          (item) =>
            item.id === this.payload.bill_receive.payment_intermediary_id
        );
        if (payment_intermediary) {
          let total_percentage_tax = 0;
          // SE FOR CRÉDITO PARCELADO ACUMULADO
          /** checked */
          if (
            payment_intermediary.credit_in_installments.receipt_method === 0 &&
            payment_method.payment_type === 1 &&
            payment_term.total_installment > 1
          ) {
            // Object.values(payment_intermediary.additional_installments).map(function (obj) {
            //   if (obj.installment <= payment_term.total_installment) {
            //     total_percentage_tax += Number(obj.tax_percentage.toString().replace(',', '.'))
            //   }
            // })
            const deadline =
              payment_intermediary.credit_in_installments.deadline;
            total_percentage_tax = Object.values(
              payment_intermediary.additional_installments
            ).find(
              (item) => item.installment === payment_term.total_installment
            ).tax_percentage;
            total_percentage_tax = Number(
              total_percentage_tax.toString().replace(",", ".")
            );
            let date = moment(self.payload.bill_receive.competence)
              .add("days", deadline)
              .format("YYYY-MM-DD");
            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            let tax_percentage_intermediary = parseFloat(
              payment_intermediary.credit_in_installments.tax_percentage
                .toString()
                .replace(",", ".")
            );
            discounts = parseFloat(discounts) + tax_percentage_intermediary;
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(
              payment_intermediary.credit_in_installments.tax_monetary
                .toString()
                .replace(",", ".")
            );
            let installment = {
              value: total_value.toFixed(2),
              index: 1,
              total_value: (total_value - discounts).toFixed(2),
              due_date: date,
              original_due_date: date,
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: discounts.toFixed(2),
            };
            this.payload.installments = [installment];
            return this.calcFinalValue();
          }
          // SE FOR CRÉDITO PARCELADO COM RECEBIMENTO PARCELADO
          /** checked */
          if (
            payment_intermediary.credit_in_installments.receipt_method === 1 &&
            payment_method.payment_type === 1 &&
            payment_term.total_installment > 1
          ) {
            /** regra antiga que acumulava todas as taxas por parcela */
            // Object.values(payment_intermediary.additional_installments).map(function (obj) {
            //   if (obj.installment <= payment_term.total_installment) {
            //     total_percentage_tax += Number(obj.tax_percentage.toString().replace(',', '.'))
            //   }
            // })
            /** regra atual que pega a taxa fixa na parcela referencia */
            total_percentage_tax = Object.values(
              payment_intermediary.additional_installments
            ).find(
              (item) => item.installment === payment_term.total_installment
            ).tax_percentage;
            total_percentage_tax = Number(
              total_percentage_tax.toString().replace(",", ".")
            );

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            let tax_percentage_intermediary = parseFloat(
              payment_intermediary.credit_in_installments.tax_percentage
                .toString()
                .replace(",", ".")
            );
            discounts = parseFloat(discounts) + tax_percentage_intermediary;
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(
              payment_intermediary.credit_in_installments.tax_monetary
                .toString()
                .replace(",", ".")
            );

            installments.map(function (obj, key) {
              let date = moment(self.payload.bill_receive.competence)
                .add("days", obj.deadline)
                .format("YYYY-MM-DD");

              let percentage = parseFloat(obj.percentage);
              let value = (percentage / 100) * total_value;

              let installment = {
                value: value.toFixed(2),
                index: obj.index,
                total_value: (
                  value -
                  discounts / payment_term.total_installment
                ).toFixed(2),
                due_date: date,
                original_due_date: date,
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: (
                  discounts / payment_term.total_installment
                ).toFixed(2),
              };
              payload_installments.push(installment);
            });
            this.payload.installments = payload_installments;
            return this.calcFinalValue();
          }
          // SE FOR CRÉDITO A VISTA
          if (
            payment_method.payment_type === 1 &&
            payment_term.total_installment === 1
          ) {
            const deadline = payment_intermediary.credit_on_sight.deadline;
            total_percentage_tax = Number(
              payment_intermediary.credit_on_sight.tax_percentage
                .toString()
                .replace(",", ".")
            );
            let date = moment(self.payload.bill_receive.competence)
              .add("days", deadline)
              .format("YYYY-MM-DD");

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(
              payment_intermediary.credit_on_sight.tax_monetary
                .toString()
                .replace(",", ".")
            );
            let installment = {
              value: total_value.toFixed(2),
              index: 1,
              total_value: (total_value - discounts).toFixed(2),
              due_date: date,
              original_due_date: date,
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: discounts.toFixed(2),
            };
            this.payload.installments = [installment];
            return this.calcFinalValue();
          }
          // SE FOR DÉBITO A VISTA
          if (
            payment_method.payment_type === 0 &&
            payment_term.total_installment === 1
          ) {
            const deadline = payment_intermediary.debit.deadline;
            total_percentage_tax = Number(
              payment_intermediary.debit.tax_percentage
                .toString()
                .replace(",", ".")
            );
            let date = moment(self.payload.bill_receive.competence)
              .add("days", parseInt(deadline) + installments[0].deadline)
              .format("YYYY-MM-DD");

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(
              payment_intermediary.debit.tax_monetary
                .toString()
                .replace(",", ".")
            );
            let installment = {
              value: total_value.toFixed(2),
              index: 1,
              total_value: (total_value - discounts).toFixed(2),
              due_date: date,
              original_due_date: date,
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: discounts.toFixed(2),
            };
            this.payload.installments = [installment];
            return this.calcFinalValue();
          }
          // SE FOR DÉBITO PARCELADO
          if (
            payment_method.payment_type === 0 &&
            payment_term.total_installment > 1
          ) {
            total_percentage_tax = Number(
              payment_intermediary.debit.tax_percentage
                .toString()
                .replace(",", ".")
            );

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(
              payment_intermediary.debit.tax_monetary
                .toString()
                .replace(",", ".")
            );
            installments.map(function (obj, key) {
              let date = moment(self.payload.bill_receive.competence)
                .add("days", obj.deadline)
                .format("YYYY-MM-DD");

              let percentage = parseFloat(obj.percentage);
              let value = (percentage / 100) * total_value;

              let installment = {
                value: value.toFixed(2),
                index: obj.index,
                total_value: (value - discounts).toFixed(2),
                due_date: date,
                original_due_date: date,
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: discounts.toFixed(2),
              };
              payload_installments.push(installment);
            });
            this.payload.installments = payload_installments;
            return this.calcFinalValue();
          }
        }

        installments.map(function (obj, key) {
          /*
           * Calculamos a data de vencimento a atribuir.
           */
          let date = moment(self.payload.bill_receive.competence)
            .add("days", obj.deadline)
            .format("YYYY-MM-DD");

          /*
           * Calculamos o valor da parcela.
           */
          let payment_total_value = total_value;
          let percentage = parseFloat(obj.percentage);
          let value = (percentage / 100) * payment_total_value;

          let installment = {
            value: value.toFixed(2),
            index: obj.index,
            total_value: value.toFixed(2),
            due_date: date,
            original_due_date: date,
            status: 0,
            paid_at: null,
            fees_fines: null,
            discounts: null,
            discounts_fees: null,
          };
          payload_installments.push(installment);
        });
        this.payload.installments = payload_installments;
      }
      if (this.payment_intermediaries_exists) {
        this.payload.installments = [];
      }
      this.$forceUpdate();
      this.calcFinalValue();
      this.calcInvoicedAmount();
    },
    async calcTotalValue(installment, index) {
      let discounts = 0;
      let fees = 0;
      let current_total_value = 0;
      let current_value = 0;
      current_total_value = parseFloat(
        installment.value.replaceAll(".", "").replace(",", ".")
      );
      current_value = parseFloat(
        installment.value.replaceAll(".", "").replace(",", ".")
      );
      current_total_value -= Number(
        installment.discounts_fees.replaceAll(".", "").replace(",", ".")
      );
      current_total_value -= Number(
        installment.discounts.replaceAll(".", "").replace(",", ".")
      );
      current_total_value += Number(
        installment.fees_fines.replaceAll(".", "").replace(",", ".")
      );
      current_total_value += fees;
      this.payload.installments[index].total_value =
        current_total_value.toFixed(2);
      this.$forceUpdate();
      await this.calcTotalValueToReceive();
      this.calcFinalValue();
    },
    initEntity() {
      /* this.loadingSpinner = true;
      this.$store
        .dispatch("entity/fetchItems")
        .then(response => {
          this.loadingSpinner = false;
          this.payload.bill_receive.type = 0;
        })
        .catch(() => {
        }); */
    },
    getPaymentTerms(set_installments = false) {
      this.payload.bill_receive.payment_term_id = null;
      const payment_methods_selected = [
        this.payload.bill_receive.payment_method_id,
      ];
      const self = this;
      this.$store.dispatch("paymentTerm/fetchItems");
      this.payment_terms_selected = this.payment_terms.filter(function (item) {
        if (item.anticipation) {
          for (var payment_method of item.payment_methods) {
            if (
              payment_methods_selected.includes(payment_method.id)
            ) {
              return item;
            }
          }
        }
      });
      if (set_installments) {
        this.generateInstallments();
      }
    },
    validHasSufficientBalance() {
      if (this.balance.replaceAll(".", "").replace(",", ".") > 0) {
        return true;
      }
      let balance = 0;
      let total = this.payload.bill_receive.total_value
        .replaceAll(".", "")
        .replace(",", ".");
      total = parseFloat(total);
      balance += total;
      for (let id of this.payload.invoices) {
        if (balance <= 0) {
          return false;
        }
        const invoice = this.invoices.find((item) => item.id === id);
        const value = Number(invoice.pending_billing);
        if (balance > value) {
          balance -= value;
        } else {
          balance = 0;
        }
      }
      return true;
    },
    calcIss() {
      const self = this;
      let total_value = 0;
      this.payload.invoices.map(function (id) {
        let invoice = self.invoices.find(function (item) {
          return item.id === id;
        });
        total_value += invoice.iss_value.toFixed(2);
      });
      this.payload.bill_receive.iss_retain = total_value;
    },
    calcInvoicedAmount() {
      const self = this;
      let total_value = 0;
      this.payload.invoices.map(function (obj) {
        let invoice = self.invoices.find(function (item) {
          return item.id == obj;
        });
        total_value += Number(invoice.pending_billing);
      });
      this.invoiced_amount = total_value.toFixed(2);
      const total_value_amount = this.payload.bill_receive.total_value
        .replace(".", "")
        .replace(",", ".");
      this.balance = (
        Number(total_value_amount) - Number(this.invoiced_amount)
      ).toFixed(2);
      if (this.balance < 1) {
        this.balance = 0;
      }
    },
  },
  mounted() {
    this.$watch(
      "payload.installments",
      function () {
        const self = this;
        this.hasInstallmentPaid = false;
        self.payload.installments.forEach(function (item) {
          if (item.status === 1) {
            self.hasInstallmentPaid = true;
          }
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
};
</script>

<style>
.custom-input-group280920241830 input.vc-custom-input-datetime {
  height: 30px;
  line-height: 30px;
  border: 1px solid #DCDFE6 !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 4px 0px #00000040 !important;
}

.custom-input-group280920241830 i {
  margin-top: 1px !important;
}
</style>

<style scoped>
.tab-switchers {
  height: 35px;
  width: 338px;
  display: inline-grid;
  grid-template-columns: 50% 50%;
}

.tab-switchers .tab {
  cursor: pointer;
  background: white;
  display: inline-block;
  align-content: center;
  box-shadow: 0px 2px 4px 0px #2f2f2f40;
}

.tab-switchers .tab.disabled {
  cursor: auto;
  background: #fbfbfb;
}

.tab-switchers .tab:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: 1px solid #DCDFE6;
}

.tab-switchers .tab:nth-child(2) {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: 1px solid #DCDFE6;
}

.tab-switchers .tab.active {
  background: #1A70B7;
}

.tab-switchers .tab.disabled .tab-content h4 {
  color: #b9b9b9;
}

.tab-switchers .tab.disabled .tab-content img {
  filter: brightness(0) invert(.7);
}

.tab-switchers .tab .tab-content h4 {
  color: #2B2D32;
}

.tab-switchers .tab.active .tab-content h4 {
  color: white !important;
}

.tab-switchers .tab.active .tab-content img {
  filter: brightness(0) invert(1);
}

.tab-content {
  display: inline-flex;
  align-items: center;
  margin-top: 4px;
}

.tab-content img {
  margin-right: 6px;
}

.installments-viewer {
  border: 1px solid #D9D9D9;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #D9D9D926;
  max-height: 560px;
}

.input-custom-group {
	display: flex;
	align-items: center;
	border-radius: 8px;
  overflow: hidden;
  height: 30px;
  border: 0.5px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.input-custom-group.primary {
  border: 0.5px solid #1F5D90;
}

.input-custom-group input {
	box-shadow: none !important;
  border: none;
  padding-left: 5px;
  border-left: 1px solid #E8E8E8;
  border-left-color: #E8E8E8;
}

.input-custom-group.primary input {
  border-left: 1px solid #1F5D90;
  border-left-color: #1F5D90;
  color: white;
}

.input-custom-group div {
	padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
	display: inline-flex;
	height: 30px;
	background: white;
	align-items: center;
	font-size: 12px;
}

.input-custom-group.primary input, .input-custom-group.primary div {
  background: #1A70B7;
}

.input-custom-group div {
	color: #606062;
}

.input-custom-group.primary div {
	color: white;
}

.input-custom-group input {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
	outline: none;
}

.dot-ellipse {
  width: 10px; /* Adjust the size as needed */
  height: 10px; /* Adjust the size as needed */
  border: 1px solid #1A70B7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-ellipse div {
  width: 6px; /* Adjust the size as needed */
  height: 6px; /* Adjust the size as needed */
  background-color: #1A70B7;
  border: 2px solid transparent;
  border-radius: 50%;
}

label {
  font-size: 12px !important;
}

.bill-modal-create {
  overflow-y: scroll !important;
}

@media (min-width: 768px) {
  .limited-row {
    max-height: 28px !important;
  }
}

.blue-divider {
  background-color: #1A70B7;
  opacity: 0.3;
  color: #1A70B7;
}

.new-minicard-green {
  background-color: #F2F7F3;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 12px;
/*  box-shadow: 0 0 5px 0 #149e5752;*/
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #d2272752;*/
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #fdef0b52;*/
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #0b7bfd52;*/
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #5d636a52;*/
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red, .new-minicard-green, .new-minicard-yellow, .new-minicard-blue, .new-minicard-gray {
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child, .new-minicard-yellow img:first-child, .new-minicard-blue img:first-child, .new-minicard-gray img:first-child {
  width: 16px;
}

.new-minicard-table div {
  display: inline-flex;
  align-items: center;
  margin-top: 1px;
}

.new-minicard-red h4, .new-minicard-green h4, .new-minicard-yellow h4, .new-minicard-blue h4, .new-minicard-gray h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}
</style>
