// Start CMC Software
import CmcIndex from  '@/views/Modules/Technology/Cmc/Index.vue';
import CreateCmc from '@/views/Modules/Technology/Cmc/Shared/CreateCmc.vue';
import EditCmc from '@/views/Modules/Technology/Cmc/Shared/EditCmc.vue';
import CmcGroup from  '@/views/Modules/Technology/Cmc/Group/Index.vue';
import CmcProduct from  '@/views/Modules/Technology/Cmc/Product/Index.vue';
import CmcAdditionalClassification from  '@/views/Modules/Technology/Cmc/AdditionalClassification/Index.vue';
import CmcSupplier from  '@/views/Modules/Technology/Cmc/Supplier/Index.vue';
import CmcSupplierOrigin from  '@/views/Modules/Technology/Cmc/Supplier/Origin/Index.vue';
import CmcDivergentPrice from  '@/views/Modules/Technology/Cmc/CmcDivergentPrice/Index.vue';
// End CMC Software

import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import Mix from  '@/views/Modules/Technology/Mix/Index.vue';
import MixReferenceHistory from  '@/views/Modules/Technology/Mix/MixReferenceHistory/Index.vue';
import MixConcretePiece from  '@/views/Modules/Technology/Mix/ConcretePiece/Index';
import MixDesigns from  '@/views/Modules/Technology/Mix/MixDesigns.vue';
import MixDesignsEdit from '@/views/Modules/Technology/Mix/MixDesignsEdit.vue';
import MixFeature from  '@/views/Modules/Technology/Mix/Feature/Index';
import MixMixes from  '@/views/Modules/Technology/Mix/Mixes.vue';
import MixPuzlAdmixture from  '@/views/Modules/Technology/Mix/PuzlAdmixture/Index';
import MixSlumpFlow from  '@/views/Modules/Technology/Mix/SlumpFlow/Index';
import MixMaximumSize from  '@/views/Modules/Technology/Mix/MaximumSize/Index';
import MixType from '@/views/Modules/Technology/Mix/Type/Index';
import MixSubtype from  '@/views/Modules/Technology/Mix/Subtype/Index';
import MixAdditionalClassification from  '@/views/Modules/Technology/Mix/AdditionalClassification/Index';
import MixResume from  '@/views/Modules/Technology/Mix/Shared/_Resume';
import Dashboard from "@/views/Modules/Technology/Dashboard/Index";
import CommercialReferenceRoute from '../../modules/technology/reference-resume/routes/cmcRoute';
// Star Adjustment
import Adjustment from  '@/views/Modules/Technology/Adjustment/Index';
import AdjustmentLeftOver from  '@/views/Modules/Technology/Adjustment/Reuse/LeftOver/Index';
import AdjustmentStabilizingAdditive from  '@/views/Modules/Technology/Adjustment/Reuse/StabilizingAdditive/Index';
import AdjustmentConcretePiece from  '@/views/Modules/Technology/Adjustment/ConcretePiece/Index';
import AdjustmentConcreteCycle from  '@/views/Modules/Technology/Adjustment/ConcreteCycle/Index';
import AdjustmentVolume from  '@/views/Modules/Technology/Adjustment/Volume/Index';
import AdjustmentTemperature from  '@/views/Modules/Technology/Adjustment/Temperature/Index';
import AdjustmentChargePoint from  '@/views/Modules/Technology/Adjustment/ChargePoint/Index';
import AdjustmentReuse from  '@/views/Modules/Technology/Adjustment/Reuse/Index';
import AdjustmentContractHour from  '@/views/Modules/Technology/Adjustment/ContractHour/Index';
import AdjustmentContractHourAdjustment from  '@/views/Modules/Technology/Adjustment/ContractHour/Adjustment/Index';
import AdjustmentType from  '@/views/Modules/Technology/Adjustment/Type/Index';
import AdjustmentLevelResponsibility from  '@/views/Modules/Technology/Adjustment/LevelResponsibility/Index';
import AdjustmentFinalSlump from  '@/views/Modules/Technology/Adjustment/FinalSlump/Index';
// End Adjustment

// Start Test "ensaios"
import Test from  '@/views/Modules/Technology/Test/Index';
import Report from  '@/views/Modules/Technology/Test/Report/Index';
import ReportHardened from  '@/views/Modules/Technology/Test/Report/Shared/HardenedState/Index';
import TestFreshState from  '@/views/Modules/Technology/Test/FreshState/Index';
import TestHardenedState from  '@/views/Modules/Technology/Test/HardenedState/Index';
import TestMoldingCapping from  '@/views/Modules/Technology/Test/MoldingCapping.vue';
import Anomaly from '@/views/Modules/Technology/Test/Anomaly/Index';
// End Test "ensaios"

// Start DRE
import DRE from  '@/views/Modules/Technology/DRE/Index';
// End DRE

// Start Molding REsume
import MoldingResume from  '@/views/Modules/Technology/MoldingResume/Index';
// End Molding Resume

import ApprovalTechnical from  '@/views/Modules/Technology/ApprovalTechnical/Index';

const technology = {
  path: '/technology',
  component: DashboardLayout,
  name: 'Tecnologia',
  children: [
    // Start Test Route "ensarios"
    {
      path: '/technology/test',
      name: 'technology.test.index',
      component: Test,
      meta: {
        global_filter: true,
        title: 'Ensaios',
        description: 'Agenda de resultados',
        icon: 'fa fa-home',
        permission: 'technology.test.index.view',
        breadcrumbs: [
          {title: 'Ensaios', name: 'configuration.test.dashboard.index'},
          {title: 'Agenda de resultados', name: null},
        ]
      }
    },
    {
      path: '/technology/technical-dre',
      name: 'technology.technical-dre.index',
      component: DRE,
      meta: {
        global_filter: true,
        title: 'Tecnologia',
        permission: 'technology.dre.index.view',
        description: 'DRE Técnico',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Tecnologia', name: 'technology.technical-dre.dashboard.index'},
          {title: 'DRE Técnico', name: null},
        ]
      }
    },
    {
      path: '/technology/test/report',
      name: 'technology.report.index',
      component: Report,
      meta: {
        global_filter: true,
        title: 'Ensaios',
        description: 'Informe de Ensaios',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Ensaios', name: 'technology.test.index'},
          {title: 'Informe de Ensaios', name: null},
        ]
      }
    },
    {
      path: '/technology/dashboard',
      name: 'technology.dashboard.index',
      component: Dashboard,
      meta: {
        title: 'Dashboard',
        description: 'Dashboard tecnologia',
        permission: 'technology.dashboard.index.view',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Tecnologia', name: 'technology.dashboard.index'},
          {title: 'Dashboard', name: null},
        ]
      }
    },
    {
      path: '/technology/test/report/hardened/:travel_uuid',
      name: 'technology.report.hardened.index',
      component: ReportHardened,
      meta: {
        title: 'Lançamento de Ensaios',
        description: 'Estado Endurecido',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Ensaios', name: 'technology.test.index'},
          {title: 'Lançamento de Ensaios', name: null},
          {title: 'Estado Endurecido', name: null},
        ]
      }
    },
    {
      path: '/technology/test/fresh-state',
      name: 'technology.fresh-state.index',
      component: TestFreshState,
      meta: {
        title: 'Estado a Fresco',
        description: 'Ensaios',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'technology.fresh-state.dashboard.index'},
          {title: 'Estado a Fresco', name: null},
        ]
      }
    },
    {
      path: '/technology/test/hardened-state',
      name: 'technology.hardened-state.index',
      component: TestHardenedState,
      meta: {
        title: 'Estado Endurecido',
        description: 'Ensaios',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'technology.hardened-state.dashboard.index'},
          {title: 'Estado Endurecido', name: null},
        ]
      }
    },
    {
      path: '/technology/test/capping-sampling',
      name: 'technology.molding-capping.index',
      component: TestMoldingCapping,
      meta: {
        title: 'Capeamento e amostragem',
        description: 'Ensaios',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'technology.capping-sampling.dashboard.index'},
          {title: 'Capeamento e amostragem', name: null},
        ]
      }
    },
    {
      path: '/technology/test/anomaly',
      name: 'technology.anomaly.index',
      component: Anomaly,
      meta: {
        title: 'Anomalias',
        description: 'Anomalias',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'technology.anomaly.dashboard.index'},
          {title: 'Anomalias', name: null},
        ]
      }
    },
    // End Test Route "ensaios"
    {
      path: '/technology/molding-resume',
      name: 'technology.molding-resume.index',
      component: MoldingResume,
      meta: {
        title: 'Resumo de moldagem',
        description: 'Resumo de moldagem',
        icon: 'fa fa-home',
        permission: 'technology.molding-resume.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'technology.molding-resume.dashboard.index'},
          {title: 'Resumo de moldagem', name: null},
        ]
      }
    },
    {
      path: '/technology/cmc/grid',
      name: 'MCC',
      meta: {
        global_filter: true,
        permission: 'technology.cmc.index.view',
        description: 'Mcc',
      },
      component: CmcIndex
    },
    {
      path: '/technology/cmc/group',
      name: 'Grupos MCC',
      meta: {
        description: 'Grupos Mcc',
      },
      component: CmcGroup
    },
    {
      path: '/technology/cmc/product',
      name: 'Produtos MCC',
      meta: {
        description: 'Produtos Mcc',
      },
      component: CmcProduct
    },
    {
      path: '/technology/cmc/additional-classification',
      name: 'Classificação Adicional MCC',
      meta: {
        description: 'Classificação adicional mcc',
      },
      component: CmcAdditionalClassification
    },
    {
      path: '/technology/cmc/supplier',
      name: 'Fornecedor MCC',
      meta: {
        description: 'Fornecedor mcc',
      },
      component: CmcSupplier
    },
    {
      path: '/technology/cmc/supplier/origin/:supplier_uuid',
      name: 'Procedência MCC',
      component: CmcSupplierOrigin
    },
    {
      path: '/technology/cmc/create',
      name: 'Novo MCC',
      component: CreateCmc
    },
    {
      path: '/technology/cmc/edit/:cmc_id',
      name: 'Editar MCC',
      component: EditCmc
    },
    {
      path: '/technology/mix/grid',
      name: 'Formulações',
      meta: {
        global_filter : true,
        permission: 'technology.mix.index.view',
        description: 'Formulações',
      },
      component: Mix
    },
    ...CommercialReferenceRoute,
    {
      path: '/technology/mix/references',
      name: 'HISTÓRICO DE ALTERAÇÕES DE REFERÊNCIA COMERCIAL E TÉCNICA',
      meta: {
        global_filter : true,
        permission: 'technology.mix.index.view',
        description: 'Histórico de Referências',
      },
      component: MixReferenceHistory
    },
    {
      path: '/technology/mix/type',
      name: 'Tipo',
      meta: {
        description: 'Tipo',
      },
      component: MixType
    },
    {
      path: '/technology/mix/subtype',
      name: 'Subtipo',
      meta: {
        description: 'Subtipo',
      },
      component: MixSubtype
    },
    {
      path: '/technology/mix/additional-classification',
      name: 'Classificação Adicional',
      meta: {
        description: 'Classificação adicional',
      },
      component: MixAdditionalClassification
    },
    {
      path: '/technology/mix/slumpflow',
      name: 'Slump/Flow',
      meta: {
        description: 'Slump/flow',
      },
      component: MixSlumpFlow
    },
    {
      path: '/technology/mix/maximum-size',
      name: 'Dimensão Máxima',
      meta: {
        description: 'Dimensão máxima',
      },
      component: MixMaximumSize
    },
    {
      path: '/technology/mix/concrete-piece',
      name: 'Peça a concretar',
      meta: {
        description: 'Peça a concretar',
      },
      component: MixConcretePiece
    },
    {
      path: '/technology/mix/feature',
      name: 'Responsabilidade',
      meta: {
        description: 'Responsabilidade',
      },
      component: MixFeature
    },
    {
      path: '/technology/mix/puzl-admixture',
      name: 'Puzl Admix',
      meta: {
        description: 'Puzl admix',
      },
      component: MixPuzlAdmixture
    },
    {
      path: '/technology/mix/mixdesigns',
      name: 'Formulações Adicionar',
      component: MixDesigns
    },
    {
      path: '/technology/mix/mixdesigns/edit/:mix_design_uuid',
      name: 'Formulações Edição',
      component: MixDesignsEdit
    },
    {
      path: '/technology/mix/mixes/:mix_design_uuid',
      name: 'Traços',
      component: MixMixes
    },
    {
      path: '/technology/mix/resume/:mix_design_uuid',
      name: 'Resumo',
      component: MixResume,
      meta: {
        title: 'Tecnologia',
        description: 'Formulações',
        // icon: 'icon-file-xml mr-2',
        breadcrumbs: [
          {title: 'Home', name: 'mix.resume.index'},
          {title: 'Resumo', name: null},
        ]
      }
    },
    {
      path: '/technology/adjustment',
      name: 'Mapa de ponto de carga',
      meta: {
        global_filter : true,
        permission: 'technology.puzl-adjustment.index.view',
        description: 'Mapa de ponto de carga',
      },
      component: Adjustment
    },
    {
      path: '/technology/adjustment/concrete-piece',
      name: 'Ajustes - Peça a Concretar',
      meta: {
        description: 'Peça a concretar',
        permission: 'puzl_adjust.concrete-piece-permissions.index.view',
      },
      component: AdjustmentConcretePiece
    },
    {
      path: '/technology/adjustment/concrete-cycle',
      name: 'Ajustes - Ciclo do Concreto',
      meta: {
        description: 'Ciclo do concreto',
        permission: 'puzl_adjust.concrete-cycle-permissions.index.view',
      },
      component: AdjustmentConcreteCycle
    },
    {
      path: '/technology/adjustment/volume',
      name: 'Ajustes - Volume Carregado',
      meta: {
        description: 'Volume carregado',
        permission: 'puzl_adjust.volume-permissions.index.view',
      },
      component: AdjustmentVolume
    },
    {
      path: '/technology/adjustment/reuse/left-over',
      name: 'Motivos de sobra/Descarte de concreto',
      meta: {
        description: 'Motivo de sobra/descarte de concreto',
      },
      component: AdjustmentLeftOver
    },
    {
      path: '/technology/adjustment/reuse/stabilizing-additive',
      name: 'Aditivos estabilizadores',
      meta: {
        description: 'Aditivos estabilizadores',
      },
      component: AdjustmentStabilizingAdditive
    },
    {
      path: '/technology/adjustment/temperature',
      name: 'Ajustes - Temperatura Ambiente',
      meta: {
        description: 'Temperatura ambiente',
        permission: 'puzl_adjust.temperature-permissions.index.view',
      },
      component: AdjustmentTemperature
    },
    {
      path: '/technology/adjustment/charge-point',
      name: 'Ajustes - Ponto de Carga',
      component: AdjustmentChargePoint,
      meta: {
        global_filter: true,
        description: 'Ponto de carga',
        permission: 'puzl_adjust.charge-point-permissions.index.view',
      },
    },
    {
      path: '/technology/adjustment/reuse',
      name: 'Ajustes - Puzl Reuse',
      meta: {
        description: 'Puzl reuse',
        permission: 'puzl_adjust.reuse-permissions.index.view',
      },
      component: AdjustmentReuse
    },
    {
      path: '/technology/adjustment/contract-hour',
      name: 'technology.contract-hour.index',
      component: AdjustmentContractHour,
      meta: {
        global_filter: true,
        title: 'Ajustes',
        description: 'Contrato e Horário',
        icon: 'fa fa-home',
        permission: 'puzl_adjust.contract-hour-permissions.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'configuration.contract-hour.dashboard.index'},
          {title: 'Ajustes - Contrato e Horário', name: null},
        ]
      }
    },
    {
      path: '/technology/adjustment/contract-hour/adjustment/:formulation_uuid',
      name: 'technology.contract-hour.adjustment.index',
      component: AdjustmentContractHourAdjustment,
      meta: {
        title: 'Contrato e Horário',
        description: 'Ajuste Formulação',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'configuration.contract-hour.adjustment.dashboard.index'},
          {title: 'Contrato e Horário - Ajuste Formulação', name: null},
        ]
      }
    },
    {
      path: '/technology/cmc/divergent-price',
      name: 'technology.cmc.divergent-price.view',
      component: CmcDivergentPrice,
      meta: {
        global_filter: true,
        title: 'Tecnologia',
        description: 'Atualização de preços',
        icon: 'fa fa-home',
        permission: 'entries.divergent-price.dashboard.index',
        breadcrumbs: [
          {title: 'Dashboard', name: 'technology.cmc.divergent-price.dashboard.index'},
          {title: 'Atualização de preços', name: null},
        ]
      },
    },
    {
      path: '/technology/adjustment/type',
      name: 'technology.adjustment.type.index.view',
      component: AdjustmentType,
      meta: {
        title: 'Tecnologia',
        description: 'Ajustes - Tipo',
        icon: 'fa fa-home',
        permission: 'puzl_adjust.type-permissions.index.view',
        global_filter: true,
        breadcrumbs: [
          {title: 'Dashboard', name: 'technology.adjustment.type.dashboard.index'},
          {title: 'Ajustes - Tipo', name: null},
        ]
      },
    },
    {
      path: '/technology/adjustment/level-responsibility',
      name: 'technology.adjustment.level-responsibility.index.view',
      component: AdjustmentLevelResponsibility,
      meta: {
        title: 'Tecnologia',
        description: 'Ajustes - Nível de aplicação',
        icon: 'fa fa-home',
        global_filter: true,
        permission: 'puzl_adjust.level-responsibility-permissions.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'technology.adjustment.level-responsibility.dashboard.index'},
          {title: 'Ajustes - Nível de aplicação', name: null},
        ]
      },
    },
    {
      path: '/technology/adjustment/final-slump',
      name: 'technology.adjustment.final-slump.index.view',
      component: AdjustmentFinalSlump,
      meta: {
        title: 'Tecnologia',
        description: 'Ajustes - Slump final',
        icon: 'fa fa-home',
        permission: 'puzl_adjust.final-slump-permissions.index.view',
        global_filter: true,
        breadcrumbs: [
          {title: 'Dashboard', name: 'technology.adjustment.final-slump.dashboard.index'},
          {title: 'Ajustes - Slump final', name: null},
        ]
      },
    },
    {
      path: '/technology/approval-technical',
      name: 'technology.approval-technical.index.view',
      component: ApprovalTechnical,
      meta: {
        title: 'Tecnologia',
        description: 'Aprovação Técnica',
        global_filter: true,
        permission: 'technology.approval-technical.index.view',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'technology.approval-technical.index'},
          {title: 'Aprovação Técnica', name: null},
        ]
      },
    },
  ]
};

export default technology;
