<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-gray d-inline-block mb-0">
            {{ this.$route.meta.breadcrumbs[2].title }}
          </h6>
          <nav
              aria-label="breadcrumb"
              class="d-none d-md-inline-block ml-md-4 text-gray"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#"><i :class="this.$route.meta.icon"></i></a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[0].title }}</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ this.$route.meta.breadcrumbs[1].title }}</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                {{ this.$route.meta.breadcrumbs[2].title }}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Header Menus -->
      <div class="row mb-3">
        <div v-if="contract_proposal" class="col-md-2 mb-2">
          <router-link
              :to="{
              path: '/commercial/contract-proposal/formulation',
              name: 'commercial.contract-proposal.formulation',
              params: { contract_proposal_uuid: contract_proposal.uuid }
            }"
          >
            <base-button block size="lg" type="light" class="text-uppercase">
              voltar
            </base-button>
          </router-link>
        </div>
      </div>

      <div>
        <div v-if="contract_proposal" class="row align-items-center pb-0 mb-3">
          <div class="col-md-12">
            <div class="checklist-item checklist-item-primary">
              <div class="checklist-info">
                <h2 class="checklist-title mb-0">
                  Proposta/Contrato:
                  <span class="small">{{ contract_proposal.code }}</span>
                </h2>
                <h2 class="checklist-title mb-0">
                  Cliente:
                  <span class="small">
                    {{
                      contract_proposal &&
                      contract_proposal.construction &&
                      contract_proposal.construction.customer_construction
                          .customer_name
                    }}
                  </span>
                </h2>
                <h2 class="checklist-title mb-0">
                  Obra:
                  <span class="small">
                    {{
                      contract_proposal &&
                      contract_proposal.construction &&
                      contract_proposal.construction.construction_name
                    }}
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid" v-if="selected && !loading">
      <!-- Start Card Set Price Minimum -->
      <card>
        <div>
          <!-- Start Insert Mixe Price Minimum -->
          <div class="ml-3">
            <div
                v-if="selected"
                class="row border-left border-3 border-primary p-2"
            >
              <div class="col-md-6">
                <h5 class="h3 mb-1">
                  {{ selected.plant && selected.plant.name || '-' }}
                </h5>
                <div
                    class="row"
                    v-for="feature in selected.features"
                    :key="feature.id"
                >
                  <div class="col-md-12">
                    <h5 class="h mb-0" v-if="selected.version">
                      {{ feature.feature.short_description }}
                      {{ format_mixe(feature.feature.period, feature.feature.period_type) }}
                      {{ feature.description }}
                      {{ feature.letter }}
                    </h5>
                    <h5 class="h mb-0" v-else>
                      {{ feature.short_description }}
                      {{ format_mixe(feature.period, feature.period_type) }}
                      {{ feature.item_description }}
                      {{ feature.letter }}
                    </h5>
                  </div>
                </div>
                <div class="mt-2">
                  <span class="p small" v-if="selected">
                    {{ selected.type && selected.type.technical_description }}
                    {{
                      selected.subtype
                          ? "|" + selected.subtype.technical_description
                          : ""
                    }}
                    |
                    {{ selected.slump_flow && selected.slump_flow.nomenclature }}
                    |
                    {{ selected.maximum_size && selected.maximum_size.description }}
                  </span>
                  <br>
                  <span class="p small">{{selected.footer}}</span>
                </div>
              </div>
              <div
                  class="col-md-5 d-flex justify-content-center align-items-center"
              >
                 <span class="h3">
                 {{
                     parseFloat(minimum_price).toLocaleString("pt-br", {
                       style: "currency",
                       currency: "BRL"
                     })
                   }} / m³
                </span>
              </div>
            </div>
            <div v-else-if="!selected && !loading">
              <badge class="secondary">Traço não encontrado para central selecionada.
                Altere a central ou crie um novo traço.
              </badge>
            </div>

            <!-- Addition Puzl Price -->
            <div
                v-if="selected && puzl_additions.length"
                class="row border-left border-3 border-indigo p-2 mt-2"
            >
              <div class="col-md-6">
                <div
                    class="row"
                    v-for="(addition, indexAddition) in puzl_additions"
                    :key="indexAddition"
                >
                  <div class="col-md-12">
                    <h5 class="h mb-0 text-indigo">
                      {{ addition && addition.name || '-' }}
                      ({{ addition.volume }} kg/<span style="text-transform: none">m</span><sup>3</sup>)
                    </h5>
                  </div>
                </div>
              </div>

              <div
                  class="col-md-5 d-flex justify-content-center align-items-center"
              >
                <span class="h3 text-indigo" v-if="puzl_addition_price">
                                  {{
                    parseFloat(puzl_addition_price.toString().replace(',', '.')).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL"
                    })
                  }} / m³

                </span>
              </div>

              <div
                  class="col-md-1 col-sm-12 d-flex justify-content-center align-items-center"
              >
                <base-button
                    block
                    size="sm"
                    type="indigo"
                    @click.prevent="addPuzlAdmixture"
                >
                  <i class="fa fa-edit text-white"></i>
                </base-button>
              </div>
            </div>
          </div>
          <!-- End Insert Mixe Price Minimum -->
          <!-- Start Edit Mixe Price Minimum -->
          <div class="row mt-4" v-if="selected && !loading">
            <div class="col-sm-2">
              <base-button
                  :disabled="loadingCycle"
                  block
                  size="sm"
                  type="warning"
                  @click.prevent="editCycle"
              >
                Editar Ciclo
              </base-button>
            </div>
          </div>
          <!-- End Edit Mixe Price Minimum -->

          <!-- Start Insert Prices Cicle -->
          <div class="mt-4" v-if="selected && !loading">
            <div class="border-left border-3 border-warning p-2"
                 v-if="transportMaximumDiff.contains || cycleMaximumDiff.contains || concretedPieceDiff.contains || Number(slumpDiff.percentage) !== 0 || Number(featureLevelDiff.percentage) !== 0">
              <div class="row mt-n2 mb-2">
                <div class="col-md-12">
                  <h4 class="text-warning">
                    <img style="height: 25px" src="/img/icons/icons8/windows/credit-card-interest.png"/>
                    CUSTOS VARIÁVEIS
                  </h4>
                </div>
              </div>
              <div class="row mt-n2" v-if="cycleMaximumDiff.contains">
                <template>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="h5 mb-0">
                          ciclo ({{ cycleMaximumDiff.diff }}
                          minutos)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5 d-flex justify-content-center align-items-center">
                    <span class="h4">
                      {{
                        cycleMaximumDiff.value.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL"
                        })
                      }}
                      /m3
                    </span>
                  </div>
                  <div class="col-md-1">
                  <span class="h4 text-danger">
                    &nbsp;
                  </span>
                  </div>
                </template>
              </div>
              <div class="row mt-n2" v-if="transportMaximumDiff.contains">
                <template>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="h5 mb-0">
                          distância ({{
                            parseFloat(transportMaximumDiff.diff).toFixed(2).toString().replace(".", ",")
                          }}
                          Km)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5 d-flex justify-content-center align-items-center">
                    <span class="h4">
                      {{
                        transportMaximumDiff.value.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL"
                        })
                      }}
                      /m3
                    </span>
                  </div>

                  <div class="col-md-1">
                    <span class="h4 text-danger">
                      &nbsp;
                    </span>
                  </div>
                </template>
              </div>
              <div class="row mt-n2" v-if="concreted_piece && concreted_piece.mix_concreted_piece && concreted_piece.percentage > 0">
                <template>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="h5 mb-0">peça a concretar -
                        {{ concreted_piece.mix_concreted_piece.description }}
                        ({{ concreted_piece.percentage }}%)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4   ">
                    <span class="h4">
                   {{
                        parseFloat(concretedPieceDiff.value).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2
                        }) + " /m3"
                      }}
                    </span>
                  </div>
                </template>
              </div>
              <div class="row " :class="!(concreted_piece && concreted_piece.mix_concreted_piece && concreted_piece.percentage != 0) && 'mt-n2'"
                   v-if="Number(slumpDiff.percentage) != 0">
                <template>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="h5 mb-0">Slump final -
                        {{ slump_flow_selected.nomenclature }}
                        ({{ slump_flow_selected.percentage.percentage }}%)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5 d-flex justify-content-center align-items-center">
                    <span class="h4">
                    {{
                        parseFloat(slumpDiff.value).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2
                        }) + " /m3"
                      }}
                    </span>
                  </div>

                  <div class="col-md-1">
                    <span class="h4 text-danger">
                      &nbsp;
                    </span>
                  </div>
                </template>
              </div>
              <div class="row "
                   :class="!(concreted_piece && concreted_piece.mix_concreted_piece && concreted_piece.percentage !== 0) && 'mt-n2'"
                   v-if="Number(featureLevelDiff.percentage) !== 0">
                <template>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="h5 mb-0">Nível de aplicação -
                        {{ feature_level_selected.label }}
                        ({{ feature_level_selected.percentage }}%)
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5 d-flex justify-content-center align-items-center">
                    <span class="h4">
                   {{
                        parseFloat(featureLevelDiff.value).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                          maximumFractionDigits: 2
                        }) + " /m3"
                      }}
                    </span>
                  </div>
                  <div class="col-md-1">
                    <span class="h4 text-danger">
                      &nbsp;
                    </span>
                  </div>
                </template>
              </div>
            </div>

            <!-- Addition Puzl Price -->
            <div class="border-left border-3 border-danger p-2 mt-2">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <span class="h5 mb-0 text-danger">
                        Preço mínimo de venda
                      </span>
                    </div>
                  </div>
                </div>

                <div
                    class="col-md-5 d-flex justify-content-center align-items-center"
                >
                  <span class="h4 text-danger">
                    {{
                      $helper.toMoney(formulation.minimum_price.minimum_price)
                    }}
                    /m3
                  </span>
                </div>
                <div class="col-md-1">
                  <span class="h4 text-danger">
                    &nbsp;
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- End Insert Prices Cicle -->

          <!-- Start Create Price -->
          <div v-if="selected && !loading">
            <validation-observer>
              <div v-if="selected" class="row d-flex justify-content-center mt-2">
                <div class="col-12">
                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                        class="col-md-4 pb-0 pt-1 col-form-label form-control-label"
                    >
                      Peças a concretar
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8">
                      <validation-provider rules="required">
                        <v-select
                            :closeOnSelect="false"
                            @input="changeConcretedPiece"
                            :reduce="piece => piece.code"
                            multiple
                            v-model="formulation.minimum_price.concreted_piece_ids"
                            :options="getConcretePieces().map(function (item) {
                              return {
                                label: item.description,
                                code: item.id
                              }
                            })">
                          <span slot="no-options">Nenhuma peça encontrada</span>
                        </v-select>
                      </validation-provider>
                    </div>
                  </div>
                  <div v-if="!selected.block_level_feature_adjustment" class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Nível de aplicação
                      <span class="text-danger" v-if="$_setting.demand_level_responsibility">&nbsp;*</span>
                    </label>
                    <div class="col-md-8">
                      <validation-provider
                          :rules="$_setting.demand_level_responsibility ? 'required' : ''"
                          v-slot="{errors}"
                      >
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select
                              :disabled="selected.block_level_feature_adjustment"
                              @change="setLevelResponsibility"
                              v-model="formulation.minimum_price.feature_level"
                              :items="feature_levels"
                              customKey="value"
                              label="label"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                        class="col-md-4 pb-0 pt-1 col-form-label form-control-label"
                    >
                      Volume previsto
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                              @keydown="($event.key === '-' || formulation.minimum_price.expected_volume.length == 0 && $event.key == 0) ? $event.preventDefault() : null"
                              v-model="formulation.minimum_price.expected_volume"
                              type="text"
                              inputmode="numeric"
                              v-mask="['#', '##', '#,#', '##,#', '###,#','#.###,#','##.###,#','###.###,#','#.###.###,#','##.###.###,#']"
                              class="form-control form-control-sm"
                          />
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                              <span style="text-transform: none">m</span><sup>3</sup>
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                        class="col-md-4 pb-0 pt-1 col-form-label form-control-label"
                    >
                      Preço de venda
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
                              maxlength="10"
                              v-money="money"
                              inputmode="numeric"
                              :class="isBiggerPrice"
                              v-model.lazy="formulation.minimum_price.value"
                              type="text"
                              class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                          <template slot="append">
                            <small class="input-group-sm p-0 m-0">
                              /<span style="text-transform: none">m</span><sup>3</sup>
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Tipo de entrega
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8" v-show="formulation.minimum_price.value == '0,00'">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select label="label" customKey="id"
                                       v-model="formulation.minimum_price.delivery_type"
                                       :items="[
          {
            label: 'Doação',
            id: 2,
          },
          {
            label: 'TRANSFERÊNCIA',
            id: 3,
          },
          {
            label: 'Uso interno',
            id: 4,
          },
          {
            label: 'Reparo de concretagem',
            id: 5,
          },
        ]">

                          </puzl-select>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div class="col-md-8" v-show="formulation.minimum_price.value != '0,00'">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select label="label" customKey="id"
                                       v-model="formulation.minimum_price.delivery_type"
                                       :items="[{label: 'Venda de concreto',id: 1}]">

                          </puzl-select>
                        </base-input>
                      </validation-provider>
                    </div>
                    <!--                    <div class="offset-4 col-4 mt-1" v-if="formulation.minimum_price.value == '0,00'">-->
                    <!--                      <base-input input-group-classes="input-group-sm">-->
                    <!--                        <input type="checkbox" class="mr-1 custom-checkbox" id="donation-checkbox"-->
                    <!--                               v-model="is_confirm_donation"/>-->
                    <!--                        <label for="donation-checkbox" class="text-danger"><b>Confirmar como doação</b></label>-->
                    <!--                      </base-input>-->
                    <!--                    </div>-->
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Retira de terceiros?
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8 mt-1">
                      <validation-provider rules="required">
                        <base-switch v-model="formulation.minimum_price.concrete_removal" type="success" offText="NÃO" onText="SIM" class="success"
                        >
                        </base-switch>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0 mb-1">
                    <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                      Total
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-8">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <input
                              maxlength="10"
                              inputmode="numeric"
                              v-money="money"
                              :disabled="true"
                              :value="getTotalPrice"
                              type="text"
                              class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1">
                    <label
                        class="col-md-4 pb-0 pt-1 col-form-label form-control-label"
                    >
                      Observações
                      <span class="text-danger"></span>
                    </label>
                    <div class="col-md-8">
                      <validation-provider>
                        <base-input input-group-classes="input-group-sm">
                          <textarea
                              type="text"
                              v-model="formulation.minimum_price.observation"
                              rows="2"
                              class="form-control form-control-sm"
                          >
                          </textarea>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="mt-3 d-flex justify-content-center">
                    <!--<div class="modal-footer">-->
                    <router-link
                        :to="{
                        path: '/commercial/contract-proposal/formulation',
                        name: 'commercial.contract-proposal.formulation',
                        params: { contract_proposal_uuid: contract_proposal.uuid }
                      }"
                    >
                      <base-button type="secondary">Cancelar</base-button>
                    </router-link>
                    <base-button
                        type="success"
                        native-type="submit"
                        :disabled="
                        invalid ||
                        !cycle ||
                        loading > 0 ||
                        !formulation.minimum_price.concreted_piece_ids.length || 
                        (!formulation.minimum_price.feature_level && !selected.block_level_feature_adjustment) || 
                        !formulation.minimum_price.expected_volume ||
                        !formulation.minimum_price.delivery_type
                      "
                        :loading="loadingSave"
                        @click.native="handleSaveFormulation"
                    >Salvar
                    </base-button>
                  </div>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </card>
      <!-- End Card Set Price Minimum -->
    </div>
    <ModalEditCycle @addCycle="setCycle" ref="editCycle"/>
    <ModalAddPuzlAdmixture
        :deleteRemove="false"
        @savePuzlAdditions="savePuzlAdditions"
        ref="addPuzlAdmixture"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {debounce, toFixed} from "@/plugins";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalEditCycle from "./_ModalEditCycle";
import ModalAddPuzlAdmixture from "./_ModalAddPuzlAdmixtures";
import {formatErrorValidation} from "@/plugins";
import {VMoney} from 'v-money';
import PuzlSelect from '@/components/PuzlSelect.vue'

export default {
  name: "EditContractProposalFormulation",
  components: {
    ModalEditCycle,
    ModalAddPuzlAdmixture,
    PuzlSelect
  },
  data() {
    return {
      format_mixe: period_types,
      loadingPlant: true,
      loadingSave: false,
      loading: 4,
      loader : false,
      invalid: false,
      once: false,
      loadingConcretePiece: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      minimum_price: 0,
      slump_flows: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      formulation: {
        minimum_price: {
          minimum_price: 0,
          concrete_removal: 0,
          delivery_type: null,
          feature_level: null,
          status: 1,
          expected_volume: "",
          value: null,
          observation: "",
          date_at: "",
          concreted_piece_ids: []
        }
      },
      cycleMaximumDiff: {
        diff: 0,
        value: 0,
        contains: false
      },
      transportMaximumDiff: {
        diff: 0,
        value: 0,
        contains: false
      },
      slumpDiff: {
        percentage: null,
        value: 0,
        contains: false,
        name: ""
      },
      featureLevelDiff: {
        percentage: null,
        value: 0,
        contains: false,
        name: ""
      },
      concretedPieceDiff: {
        percentage: null,
        value: 0,
        contains: false,
        name: ''
      },
      filter: {
        plant: '',
        mix_design_mixe: null
      },
      cycleMinimum: 0,
      puzl_additions: [],
      puzl_addition_price: 0,
      isBiggerPrice: null,
      loadingCycle: true,
      is_confirm_donation: false,
      feature_level_selected: null
    };
  },
  directives: {money: VMoney},
  computed: {
    ...mapGetters({
      $_price: "concretePrice/getPriceAdjustment",
      plants: 'plant/activeItems',
      concrete_pieces: "mixConcretePiece/activeItems",
      concreted_piece: "formulations/getConcretedPiece",
      cycle_values: "formulations/getCycleValues",
      contract_proposal: "contractProposal/show",
      selected: "formulations/show",
      feature_levels: 'mixConcretePiece/feature_levels',
      $_setting: "generalSetting/show",
    }),
    getTotalPrice() {
      const volume = (this.formulation.minimum_price.expected_volume || 1).toString().replaceAll('.', '').replace(",", ".")
      const price = (this.formulation.minimum_price.value || 1).toString().replaceAll('.', '').replace(",", ".")
      return (price * volume).toFixed(2)
    }
  },
  methods: {
    setDeliveryType() {
      const price = Number((this.formulation.minimum_price.value || '0.00').toString().replaceAll('.', '').replace(",", "."))
      if (price > 0 && this.formulation.minimum_price.delivery_type !== 1) {
        return this.formulation.minimum_price.delivery_type = 1
      }
      if (price < 0.01 && this.formulation.minimum_price.delivery_type === 1) {
        return this.formulation.minimum_price.delivery_type = null
      }
    },
    async handleSearchAdjustment() {
      let loader = this.$loading.show()
      await this.$store.dispatch('concretePrice/getPriceByPuzlAdjustment', {
        mix_design_mixe_id: this.selected.id,
        adjustments: {
          default: true,
          concrete_piece_adjustments: null,
          slump_flow_id: this.selected.slump_flow_id,
          company_plant_id: this.contract_proposal.company_plant_id,
          level_feature: this.formulation.minimum_price.feature_level,
          distance: null,
          cycle: null,
        }
      }).then(async ({data}) => {
        this.minimum_price = data.minimum_price
        this.cycleMinimum = this.parseValue(this.transportMaximumDiff.value) + this.parseValue(this.cycleMaximumDiff.value) + data.minimum_price;
        this.selected.mcc_atual = data.cmc
        this.selected.concrete_price.mcc_price = data.cmc
        this.selected.concrete_price.minimum_price = data.minimum_price
        const slump_flow_selected = this.slump_flows.find((item) => item.id === this.selected.slump_flow_id)
        if (slump_flow_selected) {
          const percentage = Number(slump_flow_selected.percentage && slump_flow_selected.percentage.percentage)
          if (percentage !== 0) {
            this.slumpDiff.contains = true
          }
          this.percentageSlump(this.minimum_price, percentage, slump_flow_selected.nomenclature);
        }
        const feature_level_selected = this.feature_levels.find((item) => item.value === this.formulation.minimum_price.feature_level)
        if (feature_level_selected) {
          const percentage = Number(feature_level_selected.percentage)
          if (percentage !== 0) {
            this.featureLevelDiff.contains = true
          }
          this.percentageLevelResponsibility(this.minimum_price, percentage, feature_level_selected.label);
        }
        let params = {
          concreted_piece_ids: this.formulation.minimum_price.concreted_piece_ids
        };
        await this.$store.dispatch("formulations/getConcretedPieces", params).then(async response => {
          if (response.data) {
            this.concretedPieceDiff.contains = true
            this.percentage(this.minimum_price, response.data.percentage, response.data.mix_concreted_piece.description);
          }
        });
        loader.hide()
      })
    },
    /**
     * Atualiza o preço minimo
     */
    setMinimumPrice() {
      this.formulation.minimum_price.minimum_price =
          parseFloat(this.slumpDiff.value) +
          parseFloat(this.featureLevelDiff.value) +
          parseFloat(this.concretedPieceDiff.value) + parseFloat(this.cycleMinimum) + parseFloat(this.puzl_addition_price);
      if (this.selected && this.loading === 0) {
        this.$snotify.info('O PREÇO MÍNIMO FOI ATUALIZADO', {
          timeout: 1000,
          icon: false,
          position: "centerTop",
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false
        });
      }
    },
    /**
     * Retorna apenas peças a concretar vinculadas a formulação
     * @returns {*}
     */
    getConcretePieces() {
      if (this.selected.block_concreted_piece_ids && this.selected.block_concreted_piece_ids.length) {

        return this.concrete_pieces.filter(item => {
          return !this.selected.block_concreted_piece_ids.includes(item.id) || this.selected.concreted_pieces.includes(item.id)
        })
      }

      if (this.selected.concreted_piece_selecteds && this.selected.concreted_piece_selecteds.length) {
        return this.concrete_pieces.filter(item => {
          return this.selected.concreted_piece_selecteds.includes(item.id) || this.selected.concreted_pieces.includes(item.id)
        })
      }
      return this.concrete_pieces
    },
    validIsBiggerPrice() {
      if (!this.formulation.minimum_price.value) {
        return true;
      }
      const value = this.formulation.minimum_price.value.replace(',', '.').replace('.', '')
      if (value > 0) {
        return false
      }
      return true
      // if (! value > 0) {
      //   return true
      // }
      // return false
    },
    changeConcretedPiece: debounce(async function () {
      let params = {
        concreted_piece_ids: this.formulation.minimum_price.concreted_piece_ids
      };
      this.concretedPieceDiff = {
        percentage: null,
        value: 0,
        contains: false,
        name: ""
      };
      this.$Progress.start();
      this.$store.dispatch("formulations/getConcretedPieces", params).then(async response => {
        this.$Progress.finish();
        this.concretedPieceDiff = {
          percentage: null,
          value: 0,
          contains: false,
          name: ""
        };
        await this.handleSearchAdjustment()
        if (response.data) {
          this.concretedPieceDiff.contains = true
          this.percentage(this.minimum_price, response.data.percentage, response.data.mix_concreted_piece.description);
        }
        if (this.loading > 0) {
          this.loading -= 1
        }
        this.setMinimumPrice()
      });
    }, 500),
    handleSaveFormulation() {
      let formulation = {
        ...this.formulation
      };
      formulation.cycle = {
        ...this.cycle
      };

      if (/^(0+|0+([,.]0+)|0+\.0+)$/.test(formulation.minimum_price.expected_volume)) {
        this.$notify({
          type: 'warning',
          message: 'Volume previsto não pode ser menor ou igual a zero!'
        });
        return;
      }

      formulation.cycle = this.prepareCycle(formulation.cycle);
      formulation.mix_design_mixe_id = this.selected.id;
      formulation.formulation_uuid = this.$route.params.formulation_uuid
      formulation.additionals = {
        cycle_maximum: this.cycleMaximumDiff,
        transport_maximum: this.transportMaximumDiff
      };
      formulation.minimum_price.value = formulation.minimum_price.value
          .replaceAll('.', '')
          .replace(',', '.')
      formulation.concrete_price = this.selected.concrete_price
      /*
        dados atualizados de imposto
       */
      formulation.concrete_price.client_puzl = this.$_price.client_puzl
      formulation.concrete_price.client_puzl_name = this.$_price.client_puzl_name
      formulation.concrete_price.cycle_name = this.$_price.cycle_name
      formulation.concrete_price.cycle_price = this.$_price.cycle_price
      formulation.concrete_price.machining_price = this.$_price.machining_price
      formulation.concrete_price.mcc_price = this.$_price.mcc_price
      formulation.concrete_price.minimum_price = this.$_price.minimum_price
      formulation.concrete_price.puzl_customer = this.$_price.puzl_customer
      formulation.concrete_price.tax_name = this.$_price.tax_name
      formulation.concrete_price.tax_price = this.$_price.tax_price
      formulation.concrete_price.transport_name = this.$_price.transport_name
      formulation.concrete_price.transport_price = this.$_price.transport_price
      formulation.concrete_price.type_name = this.$_price.type_name
      formulation.concrete_price.type_price = this.$_price.type_price

      formulation.minimum_price.puzl_additions = this.puzl_additions;
      formulation.minimum_price.old_price = this.selected.minimum_price;
      formulation.minimum_price.slump_flow_id = this.selected.slump_flow_id;
      formulation.minimum_price.mixe_minimum_price = this.selected.mixe_minimum_price
      formulation.minimum_price.minimum_price = formulation.minimum_price.minimum_price
          .toString()
          .replace(",", ".");
      formulation.minimum_price.addition_price = 0;
      formulation.contract_proposal_uuid = this.$route.params.contract_proposal_uuid;
      if (this.concretedPieceDiff.percentage) {
        formulation.additionals.concretedPiece = this.concretedPieceDiff;
      }
      if (Number(this.slumpDiff.percentage) !== 0) {
        formulation.additionals.slumpFinal = this.slumpDiff;
      }
      if (Number(this.featureLevelDiff.percentage) !== 0) {
        formulation.additionals.featureLevel = this.featureLevelDiff;
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      formulation.uuid = this.selected.uuid
      if (this.selected.block_level_feature_adjustment) {
        formulation.minimum_price.feature_level = null
      }
      this.$Progress.start();
      this.loadingSave = true;
      this.$store
          .dispatch("formulations/update", formulation)
          .then(response => {
            this.loadingSave = false;
            this.$Progress.finish();
            this.$notify({
              type: response.error_type,
              message: response.message
            });
            this.$router.push(
                "/commercial/contract-proposal/formulation/" +
                this.$route.params.contract_proposal_uuid
            );
          })
          .catch(error => {
            this.loadingSave = false;
            this.$Progress.finish();
            if (error && error.response && error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors);
              this.$notify({
                type: "danger",
                message: message
              });
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
            }
          });
    },
    prepareCycle(cycle) {
      delete cycle.transport_additional_cost;
      delete cycle.transport_maximum_distance;
      cycle.plant_id = cycle.plant.id;
      delete cycle.plant;
      delete cycle.cycle_additional_cost;
      delete cycle.cycle_additional;
      return cycle;
    },
    percentageLevelResponsibility(off, to, description) {
      off = off.toString().replace(",", ".");
      to = to.toString().replace(",", ".");
      let val = (off * to) / 100;
      val = val.toFixed(2);
      this.featureLevelDiff.percentage = to;
      this.featureLevelDiff.value = val;
      this.featureLevelDiff.name = description;
    },
    async setSlump() {
      await this.handleSearchAdjustment()
      const slump_flow_selected = this.slump_flows.find((item) => item.id === this.selected.slump_flow_id)
      if (!slump_flow_selected) {
        this.slumpDiff = {
          percentage: null,
          value: 0,
          contains: false,
          name: ""
        }
        return this.setMinimumPrice()
      }
      this.slump_flow_selected = slump_flow_selected
      const percentage = Number(slump_flow_selected.percentage && slump_flow_selected.percentage.percentage)
      if (percentage !== 0) {
        this.slumpDiff.contains = true
      }
      this.percentageSlump(this.minimum_price, percentage, slump_flow_selected.nomenclature);
      this.setMinimumPrice()
    },
    async setLevelResponsibility() {
      await this.handleSearchAdjustment()
      const feature_level_selected = this.feature_levels.find((item) => item.value === this.formulation.minimum_price.feature_level)
      if (!feature_level_selected) {
        this.featureLevelDiff = {
          percentage: null,
          value: 0,
          contains: false,
          name: ""
        }
        return this.setMinimumPrice()
      }
      this.feature_level_selected = feature_level_selected
      const percentage = Number(feature_level_selected.percentage)
      if (percentage !== 0) {
        this.featureLevelDiff.contains = true
      }
      this.percentageLevelResponsibility(this.minimum_price, percentage, feature_level_selected.label);
      return this.setMinimumPrice()
    },
    percentageSlump(off, to, description) {
      off = off.toString().replace(",", ".");
      to = to.toString().replace(",", ".");
      let val = (off * to) / 100;
      val = val.toFixed(2);
      this.slumpDiff.percentage = to;
      this.slumpDiff.value = val;
      this.slumpDiff.name = description;
    },
    getSlumpFlow() {
      return this.slump_flows.filter((item) => this.selected.slump_flow_ids.includes(item.id))
    },
    setCycle(cycle) {
      this.cycle = cycle;
      this.haveCycle = true;
      for (var index in cycle) {
        const conditions = ["total_cycle", "increse_route_truck_empty_return", "additional_cycle", "return_addition", "send_addition"];
        const contains = conditions.some(el => index.includes(el));
        if (contains) {
          continue;
        }
        if (Number(cycle[index]) <= 0) {
          this.haveCycle = false;
        }
      }
      this.cycleMaximumDiff.contains = false;
      this.cycleMaximumDiff.value = 0;
      this.cycleMaximumDiff.diff = 0;
      this.transportMaximumDiff.contains = false;
      this.transportMaximumDiff.value = 0;
      this.transportMaximumDiff.diff = 0;
      if (this.cycle.total_cycle > this.cycle.cycle_additional) {
        let diff = this.cycle.total_cycle - this.cycle.cycle_additional;
        this.cycleMaximumDiff.diff = diff;
        this.cycleMaximumDiff.value = diff * this.cycle.cycle_additional_cost;
        this.cycleMaximumDiff.contains = true;
      }
      if (this.cycle.distance > this.cycle.transport_maximum_distance) {
        let diff = this.cycle.distance - this.cycle.transport_maximum_distance;
        this.transportMaximumDiff.diff = diff;
        this.transportMaximumDiff.value = diff * this.cycle.transport_additional_cost;
        this.transportMaximumDiff.contains = true;
      }
      let selected_price = this.minimum_price;
      this.cycleMinimum = this.parseValue(this.transportMaximumDiff.value) + this.parseValue(this.cycleMaximumDiff.value) + selected_price;
      this.formulation.minimum_price.minimum_price =
          parseFloat(this.slumpDiff.value) + parseFloat(this.featureLevelDiff.value) +
          parseFloat(this.concretedPieceDiff.value) + parseFloat(this.cycleMinimum) + parseFloat(this.puzl_addition_price);
    },
    parseValue(val) {
      if (! val) {
        return val
      }
      val = val.toString().replaceAll(",", ".");
      return parseFloat(val);
    },
    percentage(off, to, description) {
      off = off.toString().replace(",", ".");
      to = to.toString().replace(",", ".");
      let val = (off * to) / 100;
      val = val.toFixed(2);
      this.concretedPieceDiff.percentage = to;
      this.concretedPieceDiff.value = val;
      this.concretedPieceDiff.name = description
    },
    editCycle() {
      this.$refs.editCycle.openModal();
    },
    addPuzlAdmixture() {
      this.$refs.addPuzlAdmixture.openModal(this.selected);
    },
    addPuzlAdmixtureDefault(selected) {
      this.$refs.addPuzlAdmixture.handleSetDefault(selected);
    },
    savePuzlAdditions(additions) {
      const toMoney = (value) => {
        value = value.toString().replace(',', '.')
        return Number(value)
      }
      this.puzl_additions = additions;
      this.puzl_addition_price = 0
      for (var item of additions) {
        this.puzl_addition_price += toMoney(item.price)
      }
      this.formulation.minimum_price.minimum_price = parseFloat(this.slumpDiff.value) + parseFloat(this.featureLevelDiff.value) + parseFloat(this.concretedPieceDiff.value) + parseFloat(this.cycleMinimum) + parseFloat(this.puzl_addition_price)
    },
    handleSetMixDesign(plant_id) {
      let filters = {
        company_plant_id: plant_id,
        construction_id: this.contract_proposal.construction.id,
        formulation_uuid: this.$route.params.formulation_uuid,
        contract_proposal_uuid: this.contract_proposal.uuid,
      };
      this.loadingCycle = true;
      this.$store
          .dispatch("formulations/getCycleValues", {
            filter: filters
          })
          .then(response => {
            this.changeConcretedPiece()
            this.loadingCycle = false;
            let cycle = {
              ...this.cycle_values
            };
            cycle["plant"] = {
              plant_name: this.selected.plant.name
            };
            cycle["distance"] = this.selected.cycle.distance
            cycle["time_mixing"] = this.selected.cycle.time_mixing
            cycle["time_construction_stay"] = this.selected.cycle.time_construction_stay
            cycle["return_duration"] = this.selected.cycle.return_duration
            cycle["send_duration"] = this.selected.cycle.send_duration
            cycle["interval_releases"] = this.selected.cycle.interval_releases
            cycle[
                "average_volume_per_bt"
                ] = this.selected.cycle.average_volume_per_bt;
            this.loading -= 1
            this.$refs.editCycle.mountData(cycle);
          });
    },
  },
  mounted() {
    this.$store.dispatch("mixConcretePiece/getFeatureLevels")
    this.loader = this.$loading.show()
    this.loading = 4
    this.transportMaximumDiff = {
      diff: 0,
      value: 0,
      contains: false
    }
    this.formulation = {
      minimum_price: {
        minimum_price: 0,
        concrete_removal: 0,
        feature_level: null,
        status: 1,
        delivery_type: null,
        expected_volume: "",
        value: null,
        observation: "",
        date_at: "",
        concreted_piece_ids: []
      }
    }
    this.concretedPieceDiff = {
      percentage: null,
      value: 0,
      contains: false,
      name: ''
    }
    this.slumpDiff = {
      percentage: null,
      value: 0,
      contains: false,
      name: ''
    }
    this.featureLevelDiff = {
      percentage: null,
      value: 0,
      contains: false,
      name: ''
    }
    this.puzl_additions = []
    this.$forceUpdate()
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingPlant = false
    })
    this.$store.dispatch("mixConcretePiece/fetchItems").then(() => {
      this.loadingConcretePiece = false;
    });
    this.$store
        .dispatch(
            "contractProposal/show",
            this.$route.params.contract_proposal_uuid
        )
        .then(() => {
          this.loading -= 1
          this.$store
              .dispatch(
                  "formulations/show",
                  this.$route.params.formulation_uuid
              )
              .then(async response => {
                this.filter.plant = this.selected.plant.id
                for (var index in this.selected.concreted_pieces) {
                  this.formulation.minimum_price.concreted_piece_ids.push(this.selected.concreted_pieces[index])
                }
                this.selected.concrete_price.minimum_price = this.selected.minimum_price_mcc
                this.formulation.minimum_price.value = this.selected.value
                this.formulation.minimum_price.observation = this.selected.observation
                this.formulation.minimum_price.feature_level = this.selected.feature_level
                this.formulation.minimum_price.delivery_type = this.selected.delivery_type
                this.formulation.minimum_price.concrete_removal = this.selected.concrete_removal
                this.formulation.minimum_price.expected_volume = parseFloat(this.selected.expected_volume).toLocaleString('pt-BR', {minimumFractionDigits: 1})
                this.puzl_additions = this.selected.puzl_additions
                this.puzl_addition_price = this.selected.puzl_addition_price
                this.addPuzlAdmixtureDefault(this.selected)
                await this.handleSearchAdjustment()
                this.handleSetMixDesign(response.data.plant.id)
                this.loading -= 1
                this.$store.dispatch('mixSlumpFlow/fetchItems').then(response => {
                  /**
                   * não utilizado store, para não ocorrer intereferencia. Método já está sendo usado no modal de adição do traço.
                   */
                  this.slump_flows = response.data
                  this.setSlump()
                  this.setLevelResponsibility()
                  this.loadingSlumpFlow = false
                })
              });
        });
    let data = new Date();
    let dateAt = data.getFullYear() + "-" + (data.getMonth() + 1) + "-" + data.getDate();
    this.formulation.minimum_price.date_at = dateAt;
    const self = this
    this.$watch('loading', function (value) {
      if (! value) {
        this.loader.hide()
      }
    })
    this.$watch("formulation.minimum_price.value", function (val) {
      self.setDeliveryType()
      if (val == "0,00") {
        return (self.isBiggerPrice = null);
      }
      val = val.toString().replaceAll('.', '')
      val = val.toString().replace(',', '.')
      if (Number(val) >= self.formulation.minimum_price.minimum_price) {
        self.isBiggerPrice = "border-success";
      } else {
        self.isBiggerPrice = "border-danger";
      }
    });
    this.$watch("formulation.minimum_price.minimum_price", function (val) {
      if (self.formulation.minimum_price.value === "0,00") {
        return self.isBiggerPrice = null;
      }
      let value = self.formulation.minimum_price.value
      value = value.toString().replaceAll('.', '')
      value = value.toString().replace(',', '.')
      if (parseFloat(val) <= parseFloat(value)) {
        self.isBiggerPrice = "border-success";
      } else {
        self.isBiggerPrice = "border-danger";
      }
    });
    // Carregar configurações gerais.
    this.$store.dispatch('generalSetting/show');
  }
};
</script>
<style>
.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid red;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  position: relative;
}

.custom-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.custom-checkbox:checked::before {
  opacity: 1;
}
</style>
