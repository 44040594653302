<template>
  <div>
    <CanceledTable
      :items="canceled_items"
      :loadingSkeleton="loadingSkeleton"
      @getReportCanceledsExcel="getReportCanceledsExcel"
      @getInfo="getInfo"
      @navegateCanceleds="navegateCanceleds"
      v-if="viewsEnum.CANCELED === isViewing && canceled_items.length"
    />
    <LeftoverTable
      :items="items"
      :loadingSkeleton="loadingSkeleton"
      @getReportExcel="getReportExcel"
      @getInfo="getInfo"
      @navegate="navegate"
      v-if="viewsEnum.LEFTOVER === isViewing && items.length"
    />
    <div
      v-if="
        (
          (viewsEnum.CANCELED === isViewing && !canceled_items.length) || (viewsEnum.LEFTOVER === isViewing && !items.length)
        ) && (!loadingSkeleton || !loadingSkeletonCanceleds)
      "
      class="container-fluid px-0 mt-5"
    >
      <div class="row px-0">
        <div class="col-12 card-table">
          <PuzlEmptyData />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CanceledTable from "./Lists/Canceled/_CanceledTable.vue";
import LeftoverTable from "./Lists/Leftover/_LeftoverTable.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";

const VIEWS_ENUM = Object.freeze({
  CANCELED: 0,
  LEFTOVER: 1,
});

export default {
  name: "ListingManager",
  props: {
    isViewing: Number,
    canceled_items: Array,
    items: Array,
    loadingSkeleton: Boolean,
    loadingSkeletonCanceleds: Boolean
  },
  components: {
    CanceledTable,
    LeftoverTable,
    PuzlEmptyData
  },
  data(){
    return {
      viewsEnum: VIEWS_ENUM,
    }
  },
  methods: {
    getReportExcel(){
      this.$emit('getReportExcel');
    },
    getReportCanceledsExcel(){
      this.$emit('getReportCanceledsExcel');
    },
    navegateCanceleds(page){
      this.$emit('navegateCanceleds', page);
    },
    navegate(page){
      this.$emit('navegate', page);
    },
    /**
     * Busca informações da viagem relacionada
    */
    async getInfo(item) {
      const data = await this.$store.dispatch('travels/getInfo', item.id).then(data => {
        return data.data
      })
      item.info = data
    },
    /**
     * Busca informações da referencia relacionada
     */
    async getRefInfo(item) {
      const data = await this.$store.dispatch('travels/getInfo', item.ref_id).then(data => {
        return data.data
      })
      item.info = data
    },
  }
}

</script>
<style>
</style>
