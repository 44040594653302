import { FORMAT, DURATION_INPUT, UNIT_OF_TIME } from "../Enums";

/**
 * A classe `DateBase` fornece uma interface para manipulação e formatação de datas.
 *
 * Esta classe é projetada para ser uma base para manipulação de datas e deve ser estendida ou instanciada
 * através de uma fábrica de datas (`DateFactory`). Os métodos fornecidos permitem operações comuns com
 * datas, como formatação, adição, subtração e comparação de datas.
 *
 * As unidades de tempo e formatos de data são definidos por enums externos, como `FORMAT`, `DURATION_INPUT`, e `UNIT_OF_TIME`.
 *
 * @example
 * // Exemplo de formatação de data
 * const instance = date.make('2024-06-17');
 * console.info(instance.format(date.FORMAT.ISO_8601));
 * // Saída esperada: '2024-06-17T00:00:00Z'
 * 
 *
 * @example
 * // Exemplo de adição de dias
 * const instance = date.make('2024-06-17');
 * const newDate = instance.add(3, date.DURATION_INPUT.DAYS);
 * console.info(newDate.format(date.FORMAT.FULL_DATE_SLASH));
 * // Saída esperada: '20/06/2024'
 *
 * @example
 * // Exemplo de subtração de dias
 * const instance = date.make('2024-06-17');
 * const previousDate = instance.subtract(5, date.DURATION_INPUT.DAYS);
 * console.info(previousDate.format(date.FORMAT.FULL_DATE_SLASH));
 * // Saída esperada: '12/06/2024'
 *
 * @example
 * // Exemplo de cálculo de diferença entre datas
 * const instance = date.make('2024-06-17');
 * const diffDays = instance.diff(new Date('2024-06-20'), date.DURATION_INPUT.DAYS);
 * console.info(diffDays);
 * // Saída esperada: 3
 *
 * @example
 * // Exemplo de verificação de ordem das datas
 * const instance = date.make('2024-06-17');
 * const isBefore = instance.isBefore(new Date('2024-06-18'));
 * console.info(isBefore);
 * // Saída esperada: true
 *
 * const isAfter = instance.isAfter(new Date('2024-06-15'));
 * console.info(isAfter);
 * // Saída esperada: true
 *
 * @example
 * // Exemplo de definição do início e fim do mês
 * const instance = date.make('2024-06-17');
 * const startOfMonth = instance.setStartOf(date.UNIT_OF_TIME.MONTH);
 * console.info(startOfMonth.format(date.FORMAT.FULL_DATE_SLASH));
 * // Saída esperada: '01/06/2024'
 *
 * const endOfMonth = instance.setEndOf(date.UNIT_OF_TIME.MONTH);
 * console.info(endOfMonth.format(date.FORMAT.FULL_DATE_SLASH));
 * // Saída esperada: '30/06/2024'
 */
class DateBase {
  /**
   * Cria uma instância de DateDay.
   *
   * @param {Date|string|number|null} date - A data inicial.
   */
  constructor(date) {
    this.date = date;
  }

  /**
   * Converte a data armazenada em `this.date` para um objeto `Date` do JavaScript.
   * @returns {Date} A data convertida.
   */
  toDate() {
    throw new Error("Instantiate the class with DateFactory");
  }

  /**
   * Formata a data.
   *
   * @param {string} formatString - A string de formatação. Use o enum FORMAT.
   * @returns {string} A data formatada.
   */
  format(formatString = FORMAT.FULL_DATE_SLASH) {
    throw new Error("Instantiate the class with DateFactory");
  }

  /**
   * Adiciona uma quantidade especificada de tempo à data.
   *
   * @param {number} amount - A quantidade de tempo a adicionar.
   * @param {string} durationInput - A duração do tempo (default: dias). Use o enum DURATION_INPUT.
   * @returns {DateBase} A instância atualizada.
   */
  add(amount, durationInput = DURATION_INPUT.DAYS) {
    throw new Error("Instantiate the class with DateFactory");
  }

  /**
   * Subtrai uma quantidade especificada de tempo da data.
   *
   * @param {number} amount - A quantidade de tempo a subtrair.
   * @param {string} durationInput - A duração do tempo (default: dias). Use o enum DURATION_INPUT.
   * @returns {DateBase} A instância atualizada.
   */
  subtract(amount, durationInput = DURATION_INPUT.DAYS) {
    throw new Error("Instantiate the class with DateFactory");
  }

  /**
   * Calcula a diferença entre esta data e outra data em uma duração do tempo específica.
   *
   * @param {Date} otherDate - A outra data para calcular a diferença.
   * @param {string} durationInput - A duração do tempo para calcular a diferença (ex: 'days', 'months'). Use o enum DURATION_INPUT.
   * @returns {number} A diferença em unidades de tempo especificadas.
   */
  diff(otherDate, durationInput = DURATION_INPUT.DAYS) {
    throw new Error("Instantiate the class with DateFactory");
  }

  /**
   * Verifica se a data atual é anterior a outra data.
   *
   * @param {Date} otherDate - A outra data para comparação.
   * @returns {boolean} true se a data atual for antes da outra data, caso contrário false.
   */
  isBefore = (otherDate) => {
    throw new Error("Instantiate the class with DateFactory");
  };

  /**
   * Verifica se a data atual é posterior a outra data.
   *
   * @param {Date} otherDate - A outra data para comparação.
   * @returns {boolean} true se a data atual for depois da outra data, caso contrário false.
   */
  isAfter = (otherDate) => {
    throw new Error("Instantiate the class with DateFactory");
  };

  /**
   * Verifica se a data atual é posterior ou igual a outra data.
   *
   * @param {Date} otherDate - A outra data para comparação.
   * @returns {boolean} true se a data atual for maior ou igual a outra data, caso contrário false.
   */
  isSameOrAfter = (otherDate) => {
    throw new Error("Instantiate the class with DateFactory");
  };

  /**
   * Define o início da unidade de tempo especificada.
   * @param {string} unitOfTime - A unidade de tempo para definir o início. O padrão é o mês. Use o enum UNIT_OF_TIME
   * @returns {DateBase} A instância atualizada.
   */
  setStartOf = (unitOfTime = UNIT_OF_TIME.MONTH) => {
    throw new Error("Instantiate the class with DateFactory");
  };

  /**
   * Define o final da unidade de tempo especificada.
   * @param {string} unitOfTime - A unidade de tempo para definir o final. O padrão é o mês. Use o enum UNIT_OF_TIME
   * @returns {DateBase} A instância atualizada.
   */
  setEndOf = (unitOfTime = UNIT_OF_TIME.MONTH) => {
    throw new Error("Instantiate the class with DateFactory");
  };
}

export default DateBase;
