<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-md-4">
          <h2 style="font-size: 1.2rem !important;" class="text-danger">
            <img height="30px" src="/img/icons/icons8/ios/duration-finance--v1_danger.png" class="mr-3"/>
            EDITAR CONDIÇÃO DE PAGAMENTO
          </h2>
        </div>
        <div class="col-md-8 pr-4">
          <div class="row d-flex justify-content-end">
            <div class="mb-md-0 mb-2 col-md-3 px-1">
              <router-link :to="{ path:'/financial/bill-receive/payment-term'}">
                <base-button
                  block
                  size="md"
                  type="light"
                  class="pb-2 pt-2 text-uppercase"
                >
                  voltar
                </base-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <hr class="blue-divider mt-2 mb-4">
    </base-header>
        <!-- Header Menus -->

    <div class="container-fluid">
      <card>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator">
            <form class="needs-validation" ref="formPaymentTerm" autocomplete="off">
              <div class="row mb-3">
                <div class="col-12 col-md-6">
                  <div class="card p-4">
                    <div class="row">
                      <div class="col-10">
                        <h5 class="h3 new-default-black-font p-1 mb-0">
                          <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3"/>
                          IDENTIFICAÇÃO
                        </h5>
                      </div>
                      <div class="col-2">
                        <base-dropdown class="float-right">
                          <button style="margin: 0;width: 95px; justify-content: center; height: 25px;display: flex; align-items: center;" slot="title-container"
                            class="btn dropdown-toggle text-uppercase colorize-btn-img"
                            type="button"
                            :class="status ? 'btn-outline-success' : 'btn-outline-danger'">
                            <img class="mr-1" v-if="status" src="/img/icons/icons8/ios/thumb-up_success.png" width="16px">
                            <img class="mr-1" v-else src="/img/icons/icons8/ios/thumbs-down_danger.png" width="16px">
                            {{ status ? 'ATIVO' : 'INATIVO' }}
                          </button>
                          <a @click.prevent="changeStatus()" class="dropdown-item text-uppercase new-default-black-font" style="font-size: 12px;">
                            {{ status ? 'INATIVO' : 'ATIVO' }}
                          </a>
                        </base-dropdown>
                      </div>
                    </div>
                    <hr class="new-default-black mt-2 mb-2">
                    <div class="row mt-3 mb-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          NOME
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <validation-provider rules="required" v-slot="{errors}">
                          <base-input input-classes="form-control-sm">
                            <input
                              type="text"
                              placeholder="7/14/21 DIAS"
                              v-model="payload.payment_term.name"
                              class="form-control form-control-sm new-default-black-font"
                              :class="errors[0] ? 'is-invalid' : 'is-valid'" />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
<!--                    <div class="row my-2 align-items-center">-->
<!--                      <div class="col-12 col-md-6">-->
<!--                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">-->
<!--                          descrição-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-12 col-md-6">-->
<!--                        <validation-provider>-->
<!--                          <base-input input-classes="form-control-sm">-->
<!--                            <input-->
<!--                              type="text"-->
<!--                              v-model="payload.payment_term.description"-->
<!--                              placeholder="BOLETO"-->
<!--                              class="form-control form-control-sm new-default-black-font" />-->
<!--                          </base-input>-->
<!--                        </validation-provider>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="row my-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          FREQUÊNCIA DO FATURAMENTO
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm new-default-black-font">
                            <puzl-select
                              :clearable="false"
                              @change="clearRangeOff"
                              v-model="payload.payment_term.range"
                              :items="range_frequency_options" />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row my-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          TIPO DO VENCIMENTO
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm new-default-black-font">
                            <puzl-select
                              :clearable="false"
                              @change="clearRangeOff"
                              v-model="payload.payment_term.expire_type"
                              :items="expire_type_range" />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row my-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 mt-n1 new-default-black-font mb-1 col-form-label form-control-label">
                          MÉTODO DE PAGAMENTO
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm new-default-black-font">
                            <puzl-select
                              multiple
                              v-model="payload.payment_methods"
                              :items="payment_methods"
                              customKey="uuid" />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row mt-2 mb-3 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          CÓDIGO EXTERNO
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <base-input input-classes="form-control-sm">
                          <input
                            type="text"
                            v-model="payload.payment_term.external_code"
                            class="form-control form-control-sm new-default-black-font" 
                          />
                        </base-input>
                      </div>
                    </div>
                     <div class="row mt-2 mb-3 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          CÓDIGO SAP
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <base-input input-classes="form-control-sm">
                          <input
                            type="text"
                            v-model="payload.payment_term.sap_code"
                            :disabled="loadingExternalCode"
                            @blur="setSapExternalCode"
                            class="form-control form-control-sm new-default-black-font" 
                          />
                        </base-input>
                      </div>
                    </div>
                    <div class="row mt-2 mb-3 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          CÓDIGO DEFLATOR
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <base-input input-classes="form-control-sm">
                          <input
                            type="text"
                            v-model="payload.payment_term.deflator_code"
                            class="form-control form-control-sm new-default-black-font" 
                          />
                        </base-input>
                      </div>
                    </div>
                  </div>
                  <div class="card p-4">
                    <h5 class="h3 new-default-black-font p-1 mb-0">
                      <img height="30px" src="/img/icons/icons8/ios/security-shield-green_primary.png" class="mr-3"/>
                      PERMISSÕES
                    </h5>
                    <hr class="new-default-black mt-2 mb-2">
                    <div class="row mt-3 mb-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          PESSOA FÍSICA
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <validation-provider rules="required">
                          <base-input class="float-right">
                            <base-switch
                              :type="'success'"
                              v-model="payload.payment_term.physical_person"
                               />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row my-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          PESSOA JURÍDICA
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <validation-provider rules="required">
                          <base-input class="float-right">
                            <base-switch
                              :type="'success'"
                              v-model="payload.payment_term.legal_person"
                               />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row my-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          EXIGE APROVAÇÃO FINANCEIRA
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <validation-provider rules="required">
                          <base-input class="float-right">
                            <base-switch
                              :type="'success'"
                              v-model="payload.payment_term.financial_approval"
                               />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row mt-2 mb-3 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          EXIGE CRÉDITO DE ANTECIPAÇÃO (FAT. MANUAL)
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                :clearable="false"
                v-model="requires_balance"
                :items="[{id: 0, name: 'Nenhum'}, {id: 1, name: 'Compensação total'}, {id: 2, name: 'Compensação parcial'},  {id: 3, name: 'Compensação igual ao crédito'}]"
              />
                      </div>
                    </div>
                    <div class="row mt-2 mb-3 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          EXIGE CRÉDITO DE ANTECIPAÇÃO (FAT. AUTOMÁTICO)
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <puzl-select
                :clearable="false"
                v-model="automatic_billing_requires_balance"
                :items="[{id: 0, name: 'Nenhum'}, {id: 1, name: 'Compensação total'}, {id: 2, name: 'Compensação parcial'},  {id: 3, name: 'Compensação igual ao crédito'}]"
              />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="card p-4">
                    <h5 class="h3 new-default-black-font p-1 mb-0">
                      <img height="30px" src="/img/icons/icons8/ios/paid-search_danger.png" class="mr-3"/>
                      DISPONIBILIDADE NO SISTEMA
                    </h5>
                    <hr class="new-default-black mt-2 mb-2">
                    <div class="row my-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          FATURAMENTO
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <validation-provider rules="required">
                          <base-input class="float-right">
                            <base-switch
                              :type="'success'"
                              v-model="payload.payment_term.allow_in_proposal"
                              @input="handleShowCompetenceDate"
                               />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div v-if="payload.payment_term.allow_in_proposal" class="row my-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          DATA DA COMPETÊNCIA
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <base-input input-classes="form-control-sm new-default-black-font">
                          <puzl-select
                            :clearable="false"
                            v-model="payload.payment_term.competence_date"
                            :items="billing_type" />
                        </base-input>
                      </div>
                    </div>
                    <div class="row my-2 align-items-center">
                      <div class="col-12 col-md-6">
                        <div class="pb-0 col-form-label new-default-black-font form-control-label">
                          ANTECIPAÇÃO
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <validation-provider rules="required">
                          <base-input class="float-right">
                            <base-switch
                              :type="'success'"
                              v-model="payload.payment_term.anticipation"
                               />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="card p-4">
                    <h5 class="h3 new-default-black-font p-1 mb-0">
                      <img height="30px" src="/img/icons/icons8/ios/how-many-quest--v2_success.png" class="mr-3"/>
                      PARCELAS
                    </h5>
                    <hr class="new-default-black mt-2 mb-2">
                    <div class="row my-1 align-items-center">
                      <div class="col-12">
                        <div class="card p-4 my-0" style="min-height: 390px;">
                          <div class="row my-2 align-items-center">
                            <div class="col-12 col-md-6">
                              <div class="col-form-label new-default-black-font form-control-label">
                                NÚMERO DE PARCELAS
                              </div>
                            </div>
                            <div class="col-12 col-md-6">
                              <validation-provider rules="required|min_value:0" v-slot="{errors}">
                                <base-input input-classes="form-control-sm">
                                  <input
                                    disabled
                                    v-on:input="
                                      payload.installment = formatString(
                                        $event.target.value,
                                        '0',
                                        '21'
                                      )
                                    "
                                    @change="generateInstallmentFields"
                                    inputmode="numeric"
                                    v-model="payload.installment"
                                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                    placeholder="nº de parcelas"
                                    class="form-control form-control-sm" />
                                </base-input>
                              </validation-provider>
                            </div>
                          </div>
                          <hr class="new-default-black mt-2 mb-2">
                          <div class="row installments-box new-default-gray mini-card mx-1 mt-3 py-3 mb-0" v-if="installment">

                            <div class="col-12"
                              v-for="installment in payload.installments"
                              :key="installment.index">
                              <div class="row align-items-center">
                                <div class="col-12 col-md-6">
                                  <div class="col-form-label new-default-black-font form-control-label" style="display: flex;align-items: center;">
                                    <i style="font-size: 5px;" class="fa fa-circle text-primary mr-3"></i>
                                    <div style="font-size: 12px;">PARCELA {{ installment.index  }}</div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="row">
                                    <div class="col-6">
                                      <validation-provider rules="required|min_value:0">
                                        <base-input input-classes="form-control-sm">
                                          <div class="input-group input-group-sm">
                                            <input
                                              @change="fillDays(installment.index)"
                                              v-on:input="
                                                installment.deadline = formatString(
                                                  $event.target.value,
                                                  '0',
                                                  '999'
                                                )
                                              "
                                              inputmode="numeric"
                                              v-model="installment.deadline"
                                              class="form-control form-control-sm" />
                                            <div class="input-group-append">
                                              <span class="input-group-text rezise-input-group">DIAS</span>
                                            </div>
                                          </div>
                                        </base-input>
                                      </validation-provider>
                                    </div>
                                    <div class="col-6">
                                      <validation-provider rules="required|min_value:0">
                                        <base-input input-classes="form-control-sm">
                                          <div class="input-group input-group-sm">
                                            <input
                                              @keyup="calcFirstInstallment"
                                              v-on:input="
                                                installment.percentage = formatString(
                                                  $event.target.value,
                                                  '0',
                                                  '999.9',
                                                  '2'
                                                )
                                              "
                                              inputmode="numeric"
                                              v-model="installment.percentage"
                                              :disabled="installment.index === 1"
                                              class="form-control form-control-sm" />
                                            <div class="input-group-append">
                                              <span class="input-group-text rezise-input-group">%</span>
                                            </div>
                                          </div>
                                        </base-input>
                                      </validation-provider>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="offset-md-4 col-md-8 mt-n1">
                  <div class="row d-flex justify-content-end">
                    <div class="col-md-3 px-1 pb-1">
                      <base-button
                        block
                        type="danger"
                        @click.prevent="$router.push({ path: '/financial/bill-receive/payment-term' })"
                        size="md"
                        style="border-radius: 4px !important;">
                          <img class="mr-2" src="/img/icons/icons8/ios/cancel.png" width="19px">
                          CANCELAR
                      </base-button>
                    </div>
                    <div class="col-md-3 px-1 pb-1">
                      <base-button
                        block
                        type="success"
                        @click="save('save')"
                        size="md"
                        style="border-radius: 4px !important;">
                          <img class="mr-2" src="/img/icons/save.png" width="19px">
                          SALVAR
                      </base-button>
                    </div>
                    <div class="col-md-3 px-1 pb-1">
                      <base-button
                        block
                        type="primary"
                        @click="save('save-and-continue')"
                        size="md"
                        style="border-radius: 4px !important;">
                          <img class="mr-2" src="/img/icons/icons8/ios/save-all.png" width="19px">
                          SALVAR E CONTINUAR
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
        </validation-observer>
      </card>
    </div>
  </div>
</template>
<script>
import {mask1, formatErrorValidation} from "@/plugins";
import {mapGetters} from "vuex";
import {checkNumberValue} from "@/helpers";
import responsive from "@/mixins/responsive";
import paymentTermMixin from "../../Mixins/paymentTermMixin";
import PuzlSelect from "@/components/PuzlSelect";
// Sap
import sapIntegrationStore from "../../../../../../modules/sap/sap-integration/store";
import { hasSap } from "../../../../../../modules/sap/shared/helpers/sapHelper";
import { initSapIntegrationSyncType } from "../../../../../../modules/sap/sap-integration/types";
import { SapTypeEnum } from "../../../../../../modules/sap/shared/enums/SapTypeEnum";

export default {
  name: 'EditPaymentTerm',
  mixins: [responsive, paymentTermMixin],
  components: {
    PuzlSelect,
  },
  data() {
    return {
      requires_balance: 0,
      automatic_billing_requires_balance: 0,
      status: true,
      installment: null,
      range_off: null,
      payment_methods: [],
      payload: {
        payment_methods: [],
        installments: [],
        payment_term: {
          name: "",
          status: true,
          competence_date: null,
          allow_in_proposal: null,
          anticipation: null,
          puzl_customer: null,
          physical_person: null,
          legal_person: null,
          financial_approval: null,
          requires_balance: 0,
          description: null,
          range: null,
          range_off: null,
          range_off_type: null,
          expire_type: null,
          external_code: null,
          deflator_code : null,
        }
      },
      expire_type_range : [
        {
          id: 1,
          name: 'Direto'
        },
        {
          id: 2,
          name: 'Fora semana'
        },
        {
          id: 3,
          name: 'Fora dezena'
        },
        {
          id: 4,
          name: 'Fora quinzena'
        },
        {
          id: 5,
          name: 'Fora mês'
        },
      ],
      billing_type: [
        {
          id: 6,
          name: 'Maior data da entrega'
        },
        {
          id: 7,
          name: 'Data do faturamento'
        },
      ],
      range_frequency_options: [
        {
          id: 1,
          name: 'Diariamente'
        },
        {
          id: 2,
          name: 'Semanalmente'
        },
        {
          id: 3,
          name: 'Mensalmente'
        },
        {
          id: 4,
          name: 'Personalizado'
        },
      ],
      loadingStore: false,
      loading: true,
      hasSap: hasSap(),
      SapTypeEnum: SapTypeEnum,
      loadingExternalCode: false,
    };
  },
  computed: {
    rangeTypes() {
      if (this.range_off > 1) {
        return ['Dias', 'Horas']
      }
      return ['Dia', 'Hora']
    }
  },
  methods: {
    handleShowCompetenceDate(){
      if(this.payload.payment_term.allow_in_proposal) this.payload.payment_term.competence_date = 6;
      else this.payload.payment_term.competence_date = null;
    },
    changeStatus()
    {
      this.payload.payment_term.status = !this.payload.payment_term.status;
      this.status = this.payload.payment_term.status;
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    async getPaymentTerm() {
      let loader = this.$loading.show()
      this.loading = true;
      this.$store.dispatch("paymentTerm/show", this.$route.params.id).then(response => {
        let methods = [];
        this.payload = response.data;
        this.payload.payment_methods.map(function (item) {
          methods.push(item.uuid);
        });

        this.payload.installment = response.data.total_installment;
        this.installment = response.data.total_installment;
        this.payload.payment_methods = methods;
        this.range_off = response.data.range_off
        this.requires_balance = Number(response.data.requires_balance),
        this.automatic_billing_requires_balance = Number(response.data.automatic_billing_requires_balance),
        this.payload.payment_term = {
          name: response.data.name ?? null,
          status: response.data.status,
          competence_date: response.data.competence_date,
          allow_in_proposal: response.data.allow_in_proposal,
          anticipation: response.data.anticipation,
          puzl_customer: response.data.puzl_customer,
          physical_person: response.data.physical_person,
          legal_person: response.data.legal_person,
          financial_approval: response.data.financial_approval,
          description: response.data.description,
          range: response.data.range,
          range_off: response.data.range_off,
          range_off_type: response.data.range_off_type,
          expire_type: response.data.expire_type,
          external_code: response.data.external_code,
          deflator_code : response.data.deflator_code
        };
        this.status = this.payload.payment_term.status;
        this.$store.dispatch("paymentMethod/fetchItems").then(response => {
          this.payment_methods = response.data;
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$Progress.finish();
          this.loading = false;
        });
        loader.hide()
        this.getSapExternalCode()
      }).catch((error) => {
        this.loading = false;
        loader.hide()
      });
    },
    generateInstallmentFields() {
      let length = this.payload.installment;
      this.payload.installments = [];
      let percentage = 0;
      let current_percentage = 100 / this.payload.installment;

      if (current_percentage % 1 != 0) {
        current_percentage = Math.floor(current_percentage);
        percentage = length * current_percentage;
      }
      for (let i = 1; i <= length; i++) {
        let installment = {
          deadline: null,
          percentage: current_percentage,
          index: i
        };
        this.payload.installments.push(installment);
        if (i == length && percentage !== 0) {
          this.payload.installments[0].percentage += 100 - percentage;
        }
      }
    },
    clearRangeOff() {
      this.payload.payment_term.range_off = null
      this.payload.payment_term.range_off_type = 0
    },
    save(submitType) {
      this.$Progress.start();
      this.loadingStore = true;
      this.payload.percentage = this.payload.installments.reduce(
        (a, b) => parseFloat(a) + parseFloat(b.percentage),
        0
      );
      this.payload.payment_term.requires_balance = this.requires_balance
      this.payload.payment_term.automatic_billing_requires_balance = this.automatic_billing_requires_balance
      this.payload.requires_balance = this.requires_balance
      this.payload.automatic_billing_requires_balance = this.automatic_billing_requires_balance
      this.payload.payment_term.range_off = this.range_off
      this.$store
        .dispatch("paymentTerm/update", this.payload)
        .then(response => {
          this.loadingStore = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          if(submitType != 'save') this.$router.push('/financial/bill-receive/payment-term');
        })
        .catch(error => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loadingStore = false;
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingStore = false;
          }
        });
    },
    async getSapExternalCode() {
      if(this.hasSap) {
        this.loadingExternalCode = true;
        const response = await sapIntegrationStore.getExternalId(this.payload.id, SapTypeEnum.keys.PAYMENT_TERM)
        this.payload.payment_term.sap_code = response;
        this.loadingExternalCode = false;
      }
    },
    async setSapExternalCode() {
      if(this.hasSap) {
        const loader = this.$loading.show();
        let data = initSapIntegrationSyncType();
        data.typeable_id = this.payload.id;
        data.type = SapTypeEnum.keys.PAYMENT_TERM;
        data.external_id = this.payload.payment_term.sap_code;
        await sapIntegrationStore.sync(data);
        loader.hide();
      }
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.getPaymentTerm();
  }
};

</script>
<style scoped>
.installments-box {
  max-height: 230px;
  overflow: auto;
}
.new-default-gray {
  background: rgba(43, 45, 50, 0.05);
}

.mini-card {
  border: 1px solid #E8E8E8;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-top-left-radius: 0.8rem !important;
  border-bottom-left-radius: 0.8rem !important;
}

.blue-divider {
  background-color: #1b6eba;
  color: #1b6eba;
  height: 0.1px;
}

.rezise-input-group {
  font-size: 11px !important;
}

.colorize-btn-img:hover img, .show img {
  filter: brightness(0) invert(1);
}
</style>
