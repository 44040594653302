<template>
  <div style="min-height: 2000px;">
    <AppFixedPageTitle title="Ordem de compra" icon="/img/icons/cart.png" />
    <AppPageHeader class="mb-3">
      <template slot="search-bar">
        <AppSearchBar :searchBarFilter.sync="searchBarFilter" @onSearchClick="listItems" @onClearClick="clearFilter">
        </AppSearchBar>
      </template>
      <!-- <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton @click.prevent="$router.push('/purchase/request/create')" text="novo"
            type="success" icon="/img/icons/plus-math--v1-white.png">
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template> -->
    </AppPageHeader>
    <AppTabSelect :items="tabSelectItems" @onTabSelectItemClick="onTabSelectItemClick" />

    <div class="container-fluid">
      <!-- CARDS -->
      <Cards class="mt-4" v-if="listType === 'cards' && !loadingSkeleton" :requests="$_purchaseOrders" @fetch="listItems" />
      <!-- TABELA -->
      <Table v-if="listType === 'table'" :contractProposalSignatures="['a']" />
      <!-- CARREGANDO -->
      <div class="d-flex" v-if="loadingSkeleton">
        <SkeletonPuzlGrid class="p-2" v-for="index in 3" />
      </div>
      <!-- SEM REGISTROS -->
      <PuzlEmptyData v-if="!loadingSkeleton && $_purchaseOrders.length <= 0" />
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import Table from './Shared/_Table';
import Cards from './Shared/_Cards'
import PuzlSelect from "@/components/PuzlSelect";
import InputDatePicker from "@/components/InputDatePicker";
import { date } from "../../../../../helpers";
import { 
  AppSearchBar, 
  AppTabSelect,
  AppPageHeader,
  AppViewTrigger, 
  AppFixedPageTitle,
  SearchBarFilterType, 
  AppPageHeaderActions,
  initSearchBarFilterType, 
  AppPageHeaderActionsButton,

} from "../../../../../components/AppGlobal";
import {PurchaseOrderStatusEnum} from "@/enum/PurchaseOrderStatusEnum";
import { PurchaseOrderListFilterType, initPurchaseOrderListFilterType } from "./types";

export default {
  name: "PurchasesOrderIndex",
  mixins: [cursorPaginate],
  components: {
    LoadingPagination,
    SkeletonPuzlGrid,
    cursorPaginate,
    PuzlEmptyData,
    Cards,
    Table,
    PuzlSelect,
    InputDatePicker,
    AppSearchBar,
    AppViewTrigger,
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppTabSelect,
  },
  computed: {
    ...mapGetters({
        $_purchaseOrders: "purchaseOrder/fetch",
        $_short_plants: "plant/activeShortItems",
    }),
    tabSelectItems() {
      return [
        {
          id: null, 
          name: 'Todos', 
          selected: this.filter.in_status.length === 0,
        },
        {
          id: PurchaseOrderStatusEnum.PENDING, 
          name: 'Pendentes', 
          selected: this.filter.in_status.includes(PurchaseOrderStatusEnum.PENDING),
        },
        {
          id: PurchaseOrderStatusEnum.OPEN, 
          name: 'ABERTAS', 
          selected: this.filter.in_status.includes(PurchaseOrderStatusEnum.OPEN),
        },
        {
          id: PurchaseOrderStatusEnum.COMPLETED, 
          name: 'Concluídas', 
          selected: this.filter.in_status.includes(PurchaseOrderStatusEnum.COMPLETED),
        },
        {
          id: PurchaseOrderStatusEnum.DENIED, 
          name: 'Negados', 
          selected: this.filter.in_status.includes(PurchaseOrderStatusEnum.DENIED),
        },
        {
          id: PurchaseOrderStatusEnum.CANCELED, 
          name: 'Canceladas', 
          selected: this.filter.in_status.includes(PurchaseOrderStatusEnum.CANCELED),
        },
      ];
    },
  },
  data() {
    return {
      loadingSkeleton: true,
      listType: 'cards',
      filter: initPurchaseOrderListFilterType(),
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      PurchaseOrderStatusEnum: PurchaseOrderStatusEnum,
    }
  },
  methods: {
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
     listItems(isAccumulateItems = false) {
      this.loadingSkeleton = true;
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        return;
      }
      this.prepareFilter();
      this.$store
        .dispatch("purchaseOrder/fetchItemsPaginate", this.filter)
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => this.loadingSkeleton = false);
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      this.filter.input_company_plant_id = this.searchBarFilter.company_plant.selected;
      this.filter.custom_search.values = this.searchBarFilter.custom_search_values;
      this.filter.deadline_range = this.searchBarFilter.range;
    },
    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Indica se deve listar os itens
     */
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      if (isRefreshList) {
        this.listItems();
      }
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        company_plant: {
          items: this.$_short_plants,
          selected: this.searchBarFilter.company_plant.selected,
        },
        range: {
          start: this.currentDate,
          end: date.make(this.currentDate)
            .add(1, date.DURATION_INPUT.YEARS)
            .format(date.FORMAT.ISO_8601),
        },
      };
    },
    /**
     * Padrão do filtro principal
     * @returns {PurchaseOrderListFilterType}
     */
    defaultFilter() {
      return {
        ...initPurchaseOrderListFilterType(),
        deadline_range: {
          start: this.currentDate,
          end: date.make(this.currentDate)
            .add(1, date.DURATION_INPUT.YEARS)
            .format(date.FORMAT.ISO_8601),
        },
      };
    },
    onTabSelectItemClick(item) {
      const isAlreadyFiltered = this.filter.in_status.length === 1 && this.filter.in_status.includes(item.id);
      if (isAlreadyFiltered) {
        return;
      };
      this.filter.in_status = item.id === null ? [] : [item.id];
      this.listItems();
    },
  },
  async mounted() {
    if (!this.$_short_plants.length) {
      await this.$store.dispatch("plant/fetchItemsActive");
    }
    this.clearFilter();
  },
};
</script>
<style scoped>
.status-button {
  border-radius: 8px;
  height: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 10rem;
  margin-right: 1rem;
  background-color: #ffff;
  font-weight: 500;
}

.header-button .base-button {
  width: 200px !important;
  height: 6px;
}

#customButton button {
  color: #4b4b50;
  border: 1px solid #DCDFE6;
  background-color: white;
}

#customButton .btn-active {
  color: white !important;
}

.green-button {
  border-radius: 10px;
  border: 1px solid #149E57;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  color: #149E57;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
