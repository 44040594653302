<template>
<div>
  <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col-lg-4">
          <h2 style="font-size: 1.2rem !important;" class="text-primary font-weight-600">
            <img height="30px" src="/img/icons/check.png" class="mr-2" />
            AVULSOS
          </h2>
        </div>
        <div class="col-md-8">
          <div class="row d-flex justify-content-end">

            <div class="mb-2 col-md-3 px-1 mr-3">
              <base-button
                @click.prevent="handleModalSearchContract"
                class="text-uppercase d-flex justify-content-center"
                type="success"
                block
              >
              <img width="20" height="20" src="https://img.icons8.com/ios/100/FFFFFF/plus-math--v1.png" alt="plus-math--v1" class="mr-2"/> novo
              </base-button>
            </div>

            <!-- <div class="col-md-2 mb-2">
                <base-button @click.prevent="handleModalSearchContract" block size="lg" type="success" class="text-uppercase d-flex justify-content-center">
                <img width="20" height="20" src="https://img.icons8.com/ios/100/FFFFFF/plus-math--v1.png" alt="plus-math--v1" class="mr-2"/> novo
                </base-button>
            </div> -->

          </div>
        </div>
      </div>
    </base-header>
    <ListDetached />
    <modal-search-contract @openCreateSingle="showModalCreateSingle" ref="modalSearchContract"/>
    <create-single
        :contractProposalId="contractProposalId"
        :contractProposalCode="contractProposalCode"
        :constructionName="constructionName"
        :customerConstructionName="customerConstructionName"
        ref="createSingle"
    />
</div>
</template>

<script>
import ListDetached from "./Shared/_List";
import ModalSearchContract from "@/views/Modules/Operational/Schedule/Shared/_ModalSearchContract";
import CreateSingle from '@/views/Modules/Financial/Billing/BillingProposal/Shared/_CreateSingle';

export default {
    name: "Index",
    components: {
      ListDetached,
      ModalSearchContract,
      CreateSingle,
    },
    data() {
        return {
            contractProposalId: null,
            contractProposalCode: null,
            constructionName: null,
            customerConstructionName: null,
        }
  },
    methods: {
        handleModalSearchContract() {
            this.$refs.modalSearchContract.openModal(true, null, null);
        },
        /**
         * @param {number} contractProposalId
         * @param {string} contractProposalCode
         * @param {string} constructionName
         * @param {string} customerConstructionName
         */
        showModalCreateSingle(contractProposalId, contractProposalCode, constructionName, customerConstructionName) {
            this.contractProposalId = contractProposalId;
            this.contractProposalCode = contractProposalCode;
            this.constructionName = constructionName;
            this.customerConstructionName = customerConstructionName;
            this.$refs.createSingle.openModal();
        },
    },
}
</script>

<style></style>
